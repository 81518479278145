/* eslint-disable react/prop-types */
import {
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
  Fragment,
} from 'react';
import propTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { MathJax } from 'better-react-mathjax';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';

import ExamSort from './ExamSort';
import ExamSelect from './ExamSelect';
import ExamCheckBox from './ExamCheckBox';
import ExamMatch from './ExamMatch';
import ExamDraw from './ExamDraw';
import ExamFill from './ExamFill';
import ExamVoice from './ExamVoice';
import ExamFinishConfirmModal from './FinishConfirmModal';
import ExamNavigation from './Navigation';
import ExamQuestionNumbers from './QuestionNumbers';
import ExamQuestion from './Question';
import ExamQuestionFill from './QuestionFill';
import ExamDrag from './ExamDrag';
// import ExamPdf from './ExamPdf';
// import ExamAls from './ExamAls';
import ExamScale from './ExamScale';
import ExamQuestionDragPopup from './QuestionPopup';

import Card from 'components/Card';
import Typography from 'components/Typography';
import Button from 'components/Button';
import ExamContext from 'context/exam.context';
import WebCam from 'components/WebCam';
import {
  IMAGE_MIME_TYPE,
  EXAM_PACKET_FINISHED,
  EXAM_STATUS_STAGE1,
  EXAM_STATUS_STAGE2,
} from 'constant';
import ExamControllerContext from 'context/examController.context';
import { call } from 'utils/caller';
import QuestionHeader from 'components/Exam/QuestionHeader';
import useBaseURL from 'hooks/useBaseURL';
import clsx from 'libs/clsx';
import { secondsToTimerText } from 'libs/time';

function Exam({ isSimulation, onAnswerChange, onFinish }) {
  const path = useBaseURL();

  /** @type {{ examId: string }} */
  const { examId } = useParams();
  const [exams] = useContext(ExamContext);
  const [
    {
      remainingTime,
      questions,
      answers,
      currentQuestionIndex,
      groupedQuestions,
    },
    dispatch,
  ] = useContext(ExamControllerContext);

  const cardContainerRef = useRef();
  const questionContainerRef = useRef();
  const answerContainerRef = useRef();
  const rightContainerRef = useRef();

  const exam = useMemo(
    () =>
      exams.filter(
        (_exam) => String(_exam.paket_ujian.idUj) === String(examId)
      )[0] || {},
    [examId, exams]
  );

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const [isFinishConfirmShow, setIsFinishConfirmShow] = useState(false);

  const answeredQuestions = useMemo(
    () =>
      groupedQuestions
        .map((_questions) =>
          _questions.every((question) => answers[question.index] !== ' ')
        )
        .filter((answered) => answered === true).length,
    [answers, groupedQuestions]
  );

  const areAllQuestionsAnswered = answeredQuestions === groupedQuestions.length;

  // Percentage of process.
  const percentage = useMemo(
    () => Math.floor((answeredQuestions / groupedQuestions.length) * 100),
    [answeredQuestions, groupedQuestions.length]
  );

  const currentGroupedQuestion = groupedQuestions[currentQuestionIndex];

  const sanitizedAdditionalQuestion = useMemo(
    () =>
      (currentGroupedQuestion[0].additionalQuestion || '').replace(
        /@url\//g,
        `${path.questionImage}st/`
      ),
    [currentGroupedQuestion, path.questionImage]
  );

  const hasAdditionalQuestion = !!currentGroupedQuestion[0].additionalQuestion;

  const isAdditionalQuestionHasArabic = /[\u0600-\u06FF]/.test(
    currentGroupedQuestion[0].additionalQuestion
  );

  const getExamQuestionRealComponent = (type) => {
    switch (type) {
      case 'pil':
        return ExamQuestion;
      case 'sort':
        return ExamQuestion;
      case 'mlt':
        return ExamQuestion;
      case 'cck':
        return ExamQuestion;
      case 'cvs':
        return ExamQuestion;
      case 'isi':
        return ExamQuestionFill;
      case 'rec':
        return ExamQuestion;
      case 'drag':
        return ExamQuestionDragPopup;
      case 'pdf':
        return ExamQuestion;
      case 'als':
        return ExamQuestion;
      case 'lik':
        return ExamQuestion;
      case 'sjt':
        return ExamQuestion;
      case 'esy':
        return ExamQuestion;
      default:
        return () => <></>;
    }
  };

  // Memilih container berdasarkan jenis pertanyaan.
  const getAnswerContainerComponent = (type) => {
    switch (type) {
      case 'pil':
        return ExamSelect;
      case 'sort':
        return ExamSort;
      case 'mlt':
        return ExamCheckBox;
      case 'cck':
        return ExamMatch;
      case 'cvs':
        return ExamDraw;
      case 'isi':
        return ExamFill;
      case 'rec':
        return ExamVoice;
      case 'drag':
        return ExamDrag;
      // case 'pdf':
      //   return ExamPdf;
      // case 'als':
      //   return ExamAls;
      case 'lik':
        return ExamScale;
      case 'sjt':
        return ExamScale;
      case 'esy':
        return ExamFill;
      default:
        return () => <></>;
    }
  };

  // Menangani apabila tombol `Sebelumnya` diklik.
  const handlePreviousButtonClick = useCallback(() => {
    if (currentQuestionIndex > 0) {
      answerContainerRef?.current?.componentWillUnmount();
      call(dispatch.prevQuestion);
      cardContainerRef.current?.scrollTo(0, 0);
    }
  }, [currentQuestionIndex, dispatch.prevQuestion]);

  // Menangani apabila tombol `Selanjutnya` diklik.
  const handleNextButtonClick = useCallback(() => {
    if (currentQuestionIndex + 1 < questions.length) {
      answerContainerRef?.current?.componentWillUnmount();
      call(dispatch.nextQuestion);
      cardContainerRef.current?.scrollTo(0, 0);
    }
  }, [currentQuestionIndex, dispatch.nextQuestion, questions.length]);

  // Menangani apabila tombol nomor pertanyaan diklik.
  const handleQuestionIndexChange = useCallback(
    (index) => {
      answerContainerRef?.current?.componentWillUnmount();
      call(dispatch.goToQuestionIndex, index);
    },
    [dispatch.goToQuestionIndex]
  );

  // Menangani apabila jawaban diubah.
  const handleAnswerChange = useCallback(
    (index, newAnswer) => onAnswerChange(index, newAnswer),
    [onAnswerChange]
  );

  // Reset answer by " ".
  const handleResetAnswer = useCallback(
    (index) => onAnswerChange(index, ' '),
    [onAnswerChange]
  );

  // Mengupdate pertanyaan.
  const handleRefreshClick = useCallback(
    async ({ done }) => {
      if (!isSimulation) {
        await call(dispatch.refreshCurrentQuestion);
      }

      done();
    },
    [dispatch.refreshCurrentQuestion, isSimulation]
  );

  // TODO: fix it
  const handleFinishButtonClick = useCallback(() => {
    if (!areAllQuestionsAnswered) {
      Swal.fire(
        'Semua pertanyaan harus dijawab terlebih dahulu',
        '',
        'warning'
      );
      return;
    }

    // if (['sort', 'cvs'].includes(currentQuestion.type)) {
    //   answerContainerRef?.current?.componentWillUnmount();
    // }
    setIsFinishConfirmShow(true);
  }, [areAllQuestionsAnswered]);

  const handleFinishConfirmCancel = useCallback(
    () => setIsFinishConfirmShow(false),
    []
  );

  const handleFinishConfirmed = useCallback(() => {
    onFinish();
  }, [onFinish]);

  return (
    <>
      <div className="grid grid-cols-12 gap-8 h-full">
        <div
          ref={cardContainerRef}
          className="2xl:col-span-9 xl:col-span-8 col-span-12 h-full overflow-y-auto"
          style={{ maxHeight: '80vh' }}
        >
          <Card ref={questionContainerRef}>
            <QuestionHeader
              isSimulation={isSimulation}
              currentQuestionIndex={currentQuestionIndex}
              questionTotal={groupedQuestions.length}
              onRefresh={handleRefreshClick}
            />

            {hasAdditionalQuestion && <hr className="my-5" />}

            {/* Paragraf tambahan */}
            {hasAdditionalQuestion && (
              <Typography
                as="div"
                className={clsx(
                  'font-readable select-none mt-5',
                  isAdditionalQuestionHasArabic && 'xl:text-3xl text-2xl'
                )}
                dir="auto"
                style={{
                  fontFamily: isAdditionalQuestionHasArabic
                    ? 'Traditional Arabic'
                    : 'inherit',
                }}
                gutterBottom
              >
                <MathJax>
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: sanitizedAdditionalQuestion,
                    }}
                  />
                </MathJax>
              </Typography>
            )}

            {currentGroupedQuestion.map((question, index) => {
              const ExamQuestionReal = getExamQuestionRealComponent(
                question.type
              );
              const AnswerContainer = getAnswerContainerComponent(
                question.type
              );

              const handleAnswerChangeWrapper = (newAnswer) => {
                handleAnswerChange(question.index, newAnswer);
              };

              return (
                <Fragment key={index}>
                  <hr className="my-5" />

                  {/* Pertanyaan */}
                  <ExamQuestionReal
                    question={question}
                    answer={answers[question.index]}
                    onAnswerChange={handleAnswerChangeWrapper}
                    onResetAnswer={() => handleResetAnswer(question.index)}
                    canResetAnswer={answers[question.index] !== ' '}
                  />

                  {/* Jawaban container */}
                  {question.type !== 'isi' && question.type !== 'drag' && (
                    <div className="mt-7">
                      <AnswerContainer
                        ref={answerContainerRef}
                        question={question}
                        answer={answers[question.index]}
                        onAnswerChange={handleAnswerChangeWrapper}
                        resetAnswer={handleResetAnswer}
                      />
                    </div>
                  )}
                </Fragment>
              );
            })}
          </Card>
        </div>

        {/* Right side container */}
        <div
          className="2xl:col-span-3 xl:col-span-3 col-span-12 flex flex-col gap-8"
          ref={rightContainerRef}
        >
          {/* Card jumlah soal */}
          <Card
            className="flex-grow"
            style={{
              minHeight: '50%',
            }}
          >
            <div className="flex flex-col gap-5 h-full">
              <div className="items-center">
                {/* Remaining time */}
                <div className="bg-white">
                  <Typography variant="small" color="muted">
                    Sisa Waktu
                  </Typography>
                  <Typography variant="subtitle1">
                    {secondsToTimerText(remainingTime)}
                  </Typography>
                </div>
              </div>

              <div className="flex items-center justify-between">
                {/* Title */}
                <Typography
                  variant="subtitle2"
                  className="flex-none font-medium pt-2"
                  gutterBottom
                >
                  Peta Soal
                </Typography>
                <Typography
                  as="span"
                  variant="small"
                  className="font-normal px-8 py-2 bg-yellow-200 text-yellow-700 rounded-full"
                >
                  {isSimulation && 'Simulasi'}
                  {!isSimulation &&
                    // eslint-disable-next-line no-nested-ternary
                    (exam.paket_ujian?.sta === EXAM_STATUS_STAGE1
                      ? 'Stage 1'
                      : exam.paket_ujian?.sta === EXAM_STATUS_STAGE2
                      ? 'Stage 2'
                      : 'Stage 3')}
                </Typography>
              </div>

              <div className="relative flex-grow">
                {/* Question number list */}
                <ExamQuestionNumbers
                  currentQuestionIndex={currentQuestionIndex}
                  groupedQuestions={groupedQuestions}
                  answers={answers}
                  onNumberButtonClick={handleQuestionIndexChange}
                />
              </div>
              <div className="flex-none">
                <div className="flex flex-wrap justify-between items-center gap-2">
                  {/* Previous button */}
                  <Button
                    type="button"
                    color="primary-outlined"
                    onClick={handlePreviousButtonClick}
                    className={clsx(currentQuestionIndex === 0 && 'invisible')}
                  >
                    Sebelumnya
                  </Button>

                  {/* Next button */}
                  <Button
                    type="button"
                    onClick={handleNextButtonClick}
                    className={clsx(
                      currentQuestionIndex + 1 === groupedQuestions.length &&
                        'invisible'
                    )}
                  >
                    Selanjutnya
                  </Button>
                </div>

                <div className="flex flex-col gap-5 mt-4">
                  {/* Finish button */}
                  <Button
                    size="custom"
                    type="button"
                    color="warning"
                    onClick={handleFinishButtonClick}
                    data-tip={
                      !areAllQuestionsAnswered &&
                      'Semua pertanyaan harus dijawab terlebih dahulu'
                    }
                    block
                  >
                    {isSimulation && 'Selesai'}
                    {!isSimulation &&
                      (parseInt(exam.paket_ujian.sta, 10) + 1 ===
                      EXAM_PACKET_FINISHED
                        ? 'Selesai'
                        : 'Selesai & Lanjutkan')}
                  </Button>

                  {!areAllQuestionsAnswered && <ReactTooltip />}
                </div>
              </div>
            </div>
          </Card>

          {/* Webcam */}
          <Card
            className="flex-none"
            style={{
              minHeight: isTabletOrMobile ? '60%' : '40%',
            }}
          >
            <WebCam
              audio={false}
              controls={false}
              screenshotFormat={IMAGE_MIME_TYPE}
            />
          </Card>
        </div>
      </div>

      {/* Bottom navigation */}
      <ExamNavigation
        currentQuestionIndex={currentQuestionIndex}
        // remainingTime={remainingTime}
        percentage={percentage}
        // questionTotal={groupedQuestions.length}
        // answeredQuestionTotal={answeredQuestions}
        // onPreviousButtonClick={handlePreviousButtonClick}
        // onNextButtonClick={handleNextButtonClick}
        style={{
          left: questionContainerRef?.current?.getBoundingClientRect().left,
          width: questionContainerRef?.current?.getBoundingClientRect().width,
          visibility: questionContainerRef.current ? 'visible' : 'hidden',
        }}
      />

      {/* Finish confirmation modal */}
      <ExamFinishConfirmModal
        isOpen={isFinishConfirmShow}
        onClose={handleFinishConfirmCancel}
        onConfirm={handleFinishConfirmed}
      />
    </>
  );
}

Exam.defaultProps = {
  isSimulation: false,
};

Exam.propTypes = {
  isSimulation: propTypes.bool,
};

export default Exam;
