import { useContext, useMemo, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import shallow from 'zustand/shallow';

import ExamCard from './ExamCard';
import StartExamConfirmation from './StartExamConfirmation';
import ExamInformation from './ExamInformation';
import useCanRefresh from './useCanRefresh';

import PhotoWithCardInformation from 'components/Helpers/PhotoWithCard/PhotoWithCardInformation';
import ExamContext from 'context/exam.context';
import ExamModel from 'models/exam.model';
import api from 'api';
import { handleHttpError } from 'utils/httpError';
import DashboardContainer from 'components/Layouts/Dashboard/DashboardContainer';
import {
  SIDEBAR_EXAM,
  EXAM_STATUS_DONE,
  EXAM_STATUS_WRONG_DATE,
  EXAM_STATUS_NOT_ACTIVE,
  EXAM_PACKET_STARTED,
  EXAM_STATUS_ACTIVE,
} from 'constant';
import WebCam from 'components/WebCam';
import Card from 'components/Card';
import HttpRequest from 'utils/httpRequest';
import Button from 'components/Button';
import { setExams } from 'actions/exam.action';
import ExamControllerContext from 'context/examController.context';
import { call } from 'utils/caller';

function HomePage() {
  const history = useHistory();
  const [exams, dispatchExam] = useContext(ExamContext);
  const [, dispatch] = useContext(ExamControllerContext);

  const canRefresh = useCanRefresh((state) => state, shallow);

  const [startConfirmationId, setStartConfirmationId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleStartButtonClick = useCallback((examId) => {
    setStartConfirmationId(examId);
  }, []);

  const handleStartConfirmationCancel = useCallback(() => {
    setStartConfirmationId(null);
  }, []);

  const handleRefreshClick = useCallback(async () => {
    if (!canRefresh.status) return;

    setIsLoading(true);
    canRefresh.enableBackByTimeout();

    try {
      const response = await new HttpRequest(api.exam.getExamPackets()).call();
      dispatchExam(setExams(response.data.data));
    } catch (err) {
      handleHttpError(err);
    } finally {
      setIsLoading(false);
    }
  }, [canRefresh, dispatchExam]);

  const handleStartExam = useCallback(
    async (examId) => {
      try {
        await call(dispatch.start, examId);
        history.push(`/dashboard/exam/${examId}`);
      } catch (err) {
        //
      }
    },
    [dispatch.start, history]
  );

  const examData = useMemo(
    () => exams.map((exam) => new ExamModel(exam)),
    [exams]
  );

  return (
    <>
      <DashboardContainer activeSidebarItem={SIDEBAR_EXAM} showProfileOnSidebar>
        <div className="grid grid-cols-12 gap-8">
          <div className="2xl:col-span-9 xl:col-span-8 col-span-7">
            <ExamInformation />

            {/* Refresh button */}
            <div className="flex justify-end mt-8">
              <Button
                type="button"
                onClick={handleRefreshClick}
                data-tip={
                  canRefresh.status
                    ? 'Refresh data ujian'
                    : `Anda dapat mencoba lagi dalam ${canRefresh.willEnabledOn} detik`
                }
                loading={isLoading}
                disabled={isLoading}
              >
                Refresh&nbsp;
                {!canRefresh.status && <>({canRefresh.willEnabledOn})</>}
              </Button>

              <ReactTooltip />
            </div>

            <div>
              {examData.map((exam, index) => {
                // const isActive = exam.status === EXAM_STATUS_ACTIVE;
                const isActive =
                  exam.status === EXAM_STATUS_ACTIVE ||
                  EXAM_PACKET_STARTED.includes(exam.status);

                let isExamBeforeDone = true;

                if (index > 0) {
                  isExamBeforeDone =
                    examData[index - 1].status === EXAM_STATUS_DONE ||
                    examData[index - 1].status === EXAM_STATUS_WRONG_DATE ||
                    (examData[index - 1].status === EXAM_STATUS_NOT_ACTIVE &&
                      isActive);
                }

                return (
                  <ExamCard
                    key={index}
                    name={exam.name}
                    // questionTotal={exam.questionTotal}
                    duration={exam.duration}
                    remainingTime={exam.remainingTime}
                    status={exam.status}
                    // percentage={exam.percentage}
                    onStartClick={() => handleStartButtonClick(exam.id)}
                    disableStartButton={
                      !isActive || (isActive && !isExamBeforeDone)
                    }
                  />
                );
              })}
            </div>
          </div>
          <div className="2xl:col-span-3 xl:col-span-4 col-span-5 flex flex-col gap-8">
            {/* Webcam */}
            <Card className="">
              <WebCam />
            </Card>

            {/* Informasi foto dengan kartu */}
            <Card>
              <PhotoWithCardInformation />
            </Card>
          </div>
        </div>
      </DashboardContainer>

      {startConfirmationId && (
        <StartExamConfirmation
          examId={startConfirmationId}
          onConfirm={() => handleStartExam(startConfirmationId)}
          onCancel={handleStartConfirmationCancel}
        />
      )}
    </>
  );
}

export default HomePage;
