import { useContext, useMemo, useEffect, useState, useCallback } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import propTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import ProfileCard from '../../ProfileCard';

import { ReactComponent as BookOpenIcon } from 'assets/icons/book-open.svg';
import { ReactComponent as QuestionMarkCircleIcon } from 'assets/icons/question-mark-circle.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/log-out.svg';
import Logo from 'components/Logo';
import ExamProgressContext from 'context/examController.context';
import { SIDEBAR_EXAM, SIDEBAR_HELP } from 'constant';
import clsx from 'libs/clsx';
import PhotoWithCardInformation from 'components/Helpers/PhotoWithCard/PhotoWithCardInformation';
import Typography from 'components/Typography';

function Topbar({ activeItem, showProfileCard }) {
  const isInExam = useRouteMatch('/dashboard/exam/:id');
  const [{ isStart, examId }] = useContext(ExamProgressContext);

  const [isTopbarLinksAbsolute, setIsTopbarLinkAbsolute] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const sidebarLinks = useMemo(
    () => [
      {
        id: SIDEBAR_EXAM,
        to: isStart === 0 ? '/dashboard' : `/dashboard/exam/${examId}`,
        icon: BookOpenIcon,
        text: 'Ujian',
      },
      {
        id: SIDEBAR_HELP,
        to: '/dashboard/bantuan',
        icon: QuestionMarkCircleIcon,
        text: 'Bantuan',
      },
    ],
    [examId, isStart]
  );

  // Adjust styling for sidebar.
  const adjustStyle = useCallback(() => {
    const parentHeight = window.screen.height;
    const sidebarChildHeight = document
      .getElementById('sidebar-child')
      .getBoundingClientRect().height;
    const sidebarLinksHeight = document
      .getElementById('sidebar-links')
      .getBoundingClientRect().height;

    if (sidebarChildHeight + sidebarLinksHeight > parentHeight) {
      setIsTopbarLinkAbsolute(false);
    } else {
      setIsTopbarLinkAbsolute(true);
    }
  }, []);

  // Listen window resize event.
  useEffect(() => {
    const handle = () => adjustStyle();
    window.addEventListener('resize', handle);

    return () => window.removeEventListener('resize', handle);
  }, [adjustStyle]);

  // Adjust style on mount.
  useEffect(() => {
    adjustStyle();
  }, [adjustStyle]);

  return (
    <div
      className={clsx(
        !isTabletOrMobile && 'hidden',
        'col-span-12 shadow-lg overflow-y-auto'
      )}
    >
      <div id="sidebar-child" className="col-span-6">
        {/* Logo */}
        <div className="px-8 mb-5">
          <Logo />
        </div>

        {showProfileCard && <ProfileCard />}

        {/* Informasi foto dengan kartu */}
        {isInExam && <PhotoWithCardInformation />}
      </div>

      {/* Topbar links */}
      <div
        id="sidebar-links"
        className={clsx(
          'col-span-6',
          isTopbarLinksAbsolute && 'inset-x-0 bottom-0',
          showProfileCard ? 'mt-2' : 'mt-10'
        )}
      >
        {sidebarLinks.map(({ id, to, icon: Icon, text }) => (
          <Link
            key={id}
            to={to}
            className={clsx(
              'block px-8 py-4 font-medium',
              id === activeItem
                ? 'bg-green-100 text-green-600 border-l-8 border-green-600 hover:bg-green-200 hover:text-green-700'
                : 'bg-white text-gray-400 border-l-8 border-transparent hover:bg-gray-100 hover:text-gray-600 hover:border-gray-600'
            )}
          >
            <div className="flex items-center gap-4 text-base">
              <Icon className="w-8" />
              <Typography as="span" className="font-medium">
                {text}
              </Typography>
            </div>
          </Link>
        ))}

        {/* Logout button */}
        <Link
          to="/logout"
          className="col-span-6 flex items-center gap-4 text-base text-red-500 border-l-8 border-transparent px-8 py-5 font-medium hover:bg-red-200 hover:text-red-600 hover:border-red-500"
        >
          <LogoutIcon className="w-8" />
          <Typography as="span" className="font-medium">
            Keluar
          </Typography>
        </Link>
      </div>
    </div>
  );
}

Topbar.propTypes = {
  activeItem: propTypes.oneOf([SIDEBAR_EXAM, SIDEBAR_HELP]).isRequired,
  showProfileCard: propTypes.bool.isRequired,
};

export default Topbar;
