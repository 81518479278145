/* eslint-disable max-classes-per-file, no-underscore-dangle */
import axios from 'axios';
import Swal from 'sweetalert2';

import { forceQuit } from './electron';
import {
  reconnectedEvent,
  subscribeReconnectedEvent,
  unsubscribeReconnectedEvent,
} from './events';

import { setAuthorizationToken } from 'utils/authorization';
import { SECOND } from 'constant';
import { ForceLogoutError } from 'utils/httpError';
import api from 'api';

// Sebagai status apakah sedang melakukan refresh token atau tidak.
let fetchingRefreshToken = false;

/**
 * Class yang digunakan untuk men-trigger atau me-listen ketika refresh token
 * berhasil diperbarui. Tujuan dibuatnya class ini adalah untuk menangani
 * kegagalan pengembalian nilai pada fungsi call di class HttpRequest
 * yang terjadi karena ada beberapa request yang membutuhkan trigger dari
 * kegagalan tersebut untuk me-retry requestnya.
 */
class TokenRefreshedEvent {
  /**
   * @public
   * @param {() => void} callback
   */
  static listen(callback) {
    window.addEventListener('TOKEN_REFRESHED', callback);
  }

  /**
   * @public
   * @param {() => void} callback
   */
  static removeListener(callback) {
    window.removeEventListener('TOKEN_REFRESHED', callback);
  }

  /**
   * @public
   */
  static dispatch() {
    window.dispatchEvent(new Event('TOKEN_REFRESHED'));
  }
}

/**
 * Class yang digunakan untuk men-trigger atau me-listen ketika ada kegagalan
 * pada proses refresh token. Tujuan dibuatnya class ini adalah untuk
 * menggagalkan semua request ketika refresh token gagal.
 */
class ForceLogoutEvent {
  /**
   * @public
   * @param {() => void} callback
   */
  static listen(callback) {
    window.addEventListener('FORCE_LOGOUT', callback);
  }

  /**
   * @public
   * @param {() => void} callback
   */
  static removeListener(callback) {
    window.removeEventListener('FORCE_LOGOUT', callback);
  }

  /**
   * @public
   */
  static dispatch() {
    window.dispatchEvent(new Event('FORCE_LOGOUT'));
  }
}

/**
 * Class yang digunakan untuk membungkus aksi http request agar pengelolaan
 * pesan error menjadi fleksibel.
 */
export default class HttpRequest {
  /**
   * @constructor
   * @param {['get'|'post'|'put'|'delete',string]} endpoint
   */
  constructor([method, url]) {
    /**
     * Method of request
     * @private
     * @type {'get'|'post'|'put'|'delete'}
     */
    this.method = method;

    /**
     * URL target of request
     * @private
     * @type {string}
     */
    this.url = url;

    /**
     * Data
     * @private
     * @type {object}
     */
    this.data = {};

    /**
     * Axios config
     * @private
     * @type {import('axios').AxiosRequestConfig}
     */
    this.config = {};

    /**
     * Callback saat terjadi kegagalan karena koneksi internet.
     * @private
     * @type {() => void}
     */
    this.onFailCallback = () => {};

    /**
     * Callback saat proses berhasil.
     * @private
     * @type {() => void}
     */
    this.onSuccessCallback = () => {};

    /**
     * Jika true, maka akan menampilkan popup saat terjadi kegagalan karena
     * token expired.
     * @private
     * @type {boolean}
     */
    this.showUnauthorizedAlert = true;

    /**
     * Jika true, berarti request saat ini mengalami kegagalan karena koneksi
     * internet.
     * @private
     * @type {boolean}
     */
    this.isRetrying = false;
  }

  /**
   * Set onFail callback.
   * @public
   * @param {() => void} callback
   * @returns {this}
   */
  onFail(callback) {
    this.onFailCallback = callback;
    return this;
  }

  /**
   * Set onSuccess callback.
   * @public
   * @param {() => void} callback
   * @returns {this}
   */
  onSuccess(callback) {
    this.onSuccessCallback = callback;
    return this;
  }

  /**
   * Set showUnauthorizedAlert to false.
   * @public
   * @returns {this}
   */
  silentUnauthorized() {
    this.showUnauthorizedAlert = false;
    return this;
  }

  /**
   * Set http request data.
   * @public
   * @param {object} data
   * @returns {this}
   */
  setData(data) {
    this.data = data;
    return this;
  }

  /**
   * Set http request config.
   * @public
   * @param {import('axios').AxiosRequestConfig} config
   * @returns {this}
   */
  setConfig(config) {
    this.config = config;
    return this;
  }

  /**
   * Memanggil axios berdasarkan http method.
   * @private
   * @returns {Promise<import('axios').AxiosResponse<any>>}
   */
  callAxios() {
    if (['get', 'delete'].includes(this.method)) {
      return axios[this.method](this.url, this.config);
    }

    if (['post', 'put'].includes(this.method)) {
      return axios[this.method](this.url, this.data, this.config);
    }

    throw new Error(`Invalid method: ${this.method}`);
  }

  /**
   * Menampilkan pesan gagal karena koneksi internet.
   * @private
   * @param {import('axios').AxiosError} err
   * @returns {Promise<import('sweetalert2').SweetAlertResult<any>>}
   */
  // eslint-disable-next-line class-methods-use-this
  showNetworkErrorAlert(err) {
    const title =
      err.message === 'Network Error'
        ? 'Koneksi Anda terputus'
        : 'Server Sedang Sibuk';

    return Swal.fire({
      title,
      text: 'Jangan khawatir jawaban tersimpan dan waktu pengerjaan berhenti sementara. Harap dipastikan jaringan internet stabil.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00a65a',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Coba Kembali',
      cancelButtonText: 'Keluar Aplikasi',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.hideLoading(),
    });
  }

  /**
   * Menampilkan pesan error server sibuk atau koneksi terputus dan menunggu
   * pilihan peserta apakah ingin mencoba kembali atau keluar dari aplikasi.
   * @private
   * @param {import('axios').AxiosError} err
   * @returns {Promise<boolean>}
   */
  askForRetrying(err) {
    return new Promise((resolve) => {
      // Ketika popup tidak muncul karena ada beberapa request yang gagal karena
      // server sibuk atau koneksi terputus, maka tunggu sampai request yang
      // men-trigger event reconnectedEvent.
      const reconnectedHandler = () => {
        unsubscribeReconnectedEvent(reconnectedHandler);
        resolve(true);
      };
      subscribeReconnectedEvent(reconnectedHandler);

      this.showNetworkErrorAlert(err).then((result) => {
        unsubscribeReconnectedEvent(reconnectedHandler);
        resolve(result.isConfirmed);
      });
    });
  }

  /**
   * Mengecek apakah error disebabkan karena access token expired.
   * @private
   * @param {import('axios').AxiosError} err
   * @returns {boolean}
   */
  isUnauthorizedError(err) {
    const isRefreshToken = /refresh$/.test(this.url);

    return (
      err?.response?.status === 401 && // Jika unauthorized
      !isRefreshToken && // Jika api yang diakses bukan refresh token
      this.showUnauthorizedAlert
    ); // Jika popup unautorized diizinkan.
  }

  /**
   * Menangani refresh token ketika tiba-tiba token expired.
   * @private
   * @param {import('axios').AxiosError} _
   * @returns {Promise}
   */
  handleUnauthorizedError(_ /* eslint-disable-line no-unused-vars */) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (!fetchingRefreshToken) {
        fetchingRefreshToken = true;

        try {
          await new Promise((resolve2, reject2) => {
            Swal.fire({
              title: 'Token Kadaluarsa',
              text: 'Sesi login Anda telah habis',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#00a65a',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Coba Kembali',
              cancelButtonText: 'Logout',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.isConfirmed) {
                new HttpRequest(api.auth.refreshJwt())
                  .setData({
                    time: Date.now(),
                  })
                  .call()
                  .then((res) => res.data)
                  .then((data) => {
                    fetchingRefreshToken = false;
                    setAuthorizationToken(data.data.token);

                    TokenRefreshedEvent.dispatch();
                    resolve2();
                  })
                  .catch(() => {
                    fetchingRefreshToken = false;
                    reject2();
                  });
              } else {
                reject2();
              }
            });
          });

          // Menampilkan alert loading.
          Swal.fire({
            title: 'Sedang memproses...',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            timer: 10 * SECOND,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          resolve(this.call());
          return;
        } catch (err) {
          // Menggunakan query supaya tidak ada event request ke /logout,
          // karena sudah pasti akan gagal.
          window.location.replace('#/logout?no-request=');

          ForceLogoutEvent.dispatch();
          reject(new ForceLogoutError());
          return;
        }
      }

      // Trigger ketika refresh token berhasil di-refresh.
      const handleTokenRefreshed = async () => {
        // eslint-disable-next-line no-use-before-define
        ForceLogoutEvent.removeListener(handleForceLogout);
        TokenRefreshedEvent.removeListener(handleTokenRefreshed);

        resolve(await this.call());
      };

      // Trigger ketika refresh token gagal di-refresh.
      const handleForceLogout = () => {
        ForceLogoutEvent.removeListener(handleForceLogout);
        TokenRefreshedEvent.removeListener(handleTokenRefreshed);

        reject(new ForceLogoutError());
      };

      // Wait until refresh token successful or failure.
      TokenRefreshedEvent.listen(handleTokenRefreshed);
      ForceLogoutEvent.listen(handleForceLogout);
    });
  }

  /**
   * Menangani ketika http request gagal.
   * @private
   * @param {import('axios').AxiosError} err
   * @returns {Promise}
   */
  async handleError(err) {
    // Me-return reject saat terjadi kegagalan yang bukan disebabkan oleh
    // server sibuk atau koneksi internet.
    if (err.response && !this.isUnauthorizedError(err)) {
      return Promise.reject(err);
    }

    // Memanggil fungsi onFailCallback saat request yang pertama kali dipanggil
    // gagal.
    if (!this.isRetrying) {
      this.onFailCallback();
      this.isRetrying = true;
    }

    // Jika terjadi unauthorized error.
    if (this.isUnauthorizedError(err)) {
      return this.handleUnauthorizedError(err);
    }

    // retry akan bernilai true jika tombol "coba lagi" diklik.
    const retry = await this.askForRetrying(err);
    if (!retry) return forceQuit();

    // Menampilkan alert loading.
    Swal.fire({
      title: 'Sedang memproses...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      timer: 10 * SECOND,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // Memanggil ulang request yang baru saja gagal.
    return Promise.resolve(this.call());
  }

  /**
   * Menangani ketika http request sukses.
   * @private
   */
  handleSuccess() {
    if (this.isRetrying) {
      this.onSuccessCallback();
      window.dispatchEvent(reconnectedEvent);
      Swal.close();

      // Menampilkan pesan terkoneksi kembali.
      Swal.fire({
        title: 'Anda Telah Terkoneksi Kembali',
        icon: 'success',
        confirmButtonColor: '#00a65a',
        timer: 3 * SECOND,
      });
    }
  }

  /**
   * Mengeksekusi http request.
   * @returns {Promise<import('axios').AxiosResponse<any>>}
   */
  async call() {
    try {
      const result = await this.callAxios();
      this.handleSuccess();

      return Promise.resolve(result);
    } catch (err) {
      if (axios.isCancel(err)) {
        return Promise.reject(err);
      }

      if (err instanceof ForceLogoutError) {
        return Promise.reject(err);
      }

      // eslint-disable-next-line no-return-await
      return await this.handleError(err);
    }
  }
}
