import { SET_EXAM, SET_EXAMS, UNSET_EXAM, UNSET_EXAMS } from 'constant';

export const initialExamState = [];

export default function examReducer(state = initialExamState, action) {
  switch (action.type) {
    case SET_EXAM: {
      const examId = action.payload.paket_ujian.idUj;
      return state.map((exam) =>
        exam.paket_ujian.idUj === examId ? action.payload : exam
      );
    }
    case SET_EXAMS: {
      return action.payload;
    }
    case UNSET_EXAM: {
      const examId = action.payload.paket_ujian.idUj;
      return state.filter((exam) => exam.paket_ujian.idUj !== examId);
    }
    case UNSET_EXAMS: {
      return [];
    }
    default: {
      console.error(`Unhandled action type: ${action.type}`);
      return state;
    }
  }
}
