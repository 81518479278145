/* eslint-disable react/prop-types */
import { ReactComponent as WarningIcon } from 'assets/illustrations/warning.svg';
import Button from 'components/Button';
import { Modal, ModalContent } from 'components/Modal';
import Typography from 'components/Typography';

function WarningModal({ isOpen, title, text, onClose }) {
  return (
    <Modal isOpen={isOpen} size="sm">
      <ModalContent className="px-6 py-9 text-center">
        <WarningIcon className="mx-auto" />

        <Typography
          variant="subtitle1"
          className="text-gray-800 font-medium"
          gutterBottom
        >
          {title}
        </Typography>

        <Typography className="font-normal text-gray-400">{text}</Typography>

        <Button className="mt-8" onClick={onClose} block>
          Oke
        </Button>
      </ModalContent>
    </Modal>
  );
}

export default WarningModal;
