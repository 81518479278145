import { Redirect, Route, Switch } from 'react-router-dom';
import { useEffect } from 'react';

import SplashScreen from 'components/Helpers/SplashScreen';
import LoginPage from 'pages/auth/Login';
import LogoutPage from 'pages/auth/Logout';
import { GuestRoute, ProtectedRoute } from 'middlewares/route';
import HomePage from 'pages/Home';
import Dashboard from 'components/Layouts/Dashboard';
import BantuanPage from 'pages/Bantuan';
import EventHandler from 'components/Helpers/EventHandler';
import useTokenManagement from 'hooks/useTokenManagement';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import usePermissionHandler from 'hooks/usePermissionHandler';
import ExamPage from 'pages/Exam';
import useChecker from 'hooks/useChecker';
import useConfig from 'hooks/useConfig';
import SimulasiPage from 'pages/Simulasi';
import { AlertHandler } from 'hooks/useAlert';

function App() {
  useConfig();
  useChecker();
  useTokenManagement();

  const isAuthenticated = useIsAuthenticated();
  const permissionHandler = usePermissionHandler();

  useEffect(() => {
    permissionHandler.requestAllPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <EventHandler />
      <AlertHandler />

      <SplashScreen>
        <Switch>
          <Route
            path="/"
            exact
            component={() => (
              <Redirect to={isAuthenticated ? '/dashboard' : '/login'} />
            )}
          />

          <GuestRoute path="/login" exact component={LoginPage} />
          <Route path="/logout" exact component={LogoutPage} />

          <Route
            path="/dashboard"
            render={() => (
              <Dashboard>
                <Switch>
                  <ProtectedRoute
                    path="/dashboard"
                    exact
                    component={HomePage}
                  />
                  <ProtectedRoute
                    path="/dashboard/exam/:examId"
                    exact
                    component={ExamPage}
                  />
                  <ProtectedRoute
                    path="/dashboard/bantuan"
                    exact
                    component={BantuanPage}
                  />
                  <ProtectedRoute
                    path="/dashboard/simulasi"
                    exact
                    component={SimulasiPage}
                  />
                </Switch>
              </Dashboard>
            )}
          />
        </Switch>
      </SplashScreen>
    </>
  );
}

export default App;
