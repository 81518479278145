/**
 * @typedef IQuestionModel
 * @property {number} id ID pertanyaan
 * @property {string} type Jenis soal
 * @property {string|null} question Text pertanyaan
 * @property {string|null} additional Tcck
 * @property {string|null} additionalQuestion Text pertanyaan tambahan
 * @property {string|null} image Nama gambar
 * @property {string} subjectMatter Materi
 * @property {string[]|null} choices Pilihan jawaban yang tersedia
 */

/**
 * Question model
 * @param {object} question
 * @returns {IQuestionModel}
 */
export default function QuestionModel(question, index) {
  this.index = index;

  this.id = question.id;
  this.question = question.tks;
  this.additionalQuestion = question.ttb || question.stimulus_text || '';
  this.instruction = question.ptjk;
  this.image = question.gbr;
  this.type = question.jns;
  this.subjectMatter = question.mtr;
  this.additional = question.tcck;

  this.stimulus_id = question.stimulus_id;
  this.stimulus_text = question.stimulus_text;

  this.choices = Array.from(Array(6).keys())
    .map((v, i) => question[`pl${i + 1}`])
    .filter((v) => v !== '')
    .filter((v) => v !== null);

  return this;
}
