import { useCallback, useContext } from 'react';

import Card from 'components/Card';
import Help from 'components/Help';
import DashboardContainer from 'components/Layouts/Dashboard/DashboardContainer';
import { SIDEBAR_HELP } from 'constant';
import WebCam from 'components/WebCam';
import api from 'api';
import UserContext from 'context/user.context';
import { setUser } from 'actions/user.action';
import { handleHttpError } from 'utils/httpError';
import PhotoWithCardInformation from 'components/Helpers/PhotoWithCard/PhotoWithCardInformation';
import HttpRequest from 'utils/httpRequest';
import Topbar from 'components/Layouts/Dashboard/Topbar';

function BantuanPage() {
  const [, dispatchUser] = useContext(UserContext);

  const handleHelpSuccess = useCallback(async () => {
    try {
      const result = await new HttpRequest(api.profile.detail()).call();
      const user = result.data.data;
      dispatchUser(setUser(user));
    } catch (err) {
      handleHttpError(err);
    }
  }, [dispatchUser]);

  return (
    <DashboardContainer activeSidebarItem={SIDEBAR_HELP} showProfileOnSidebar>
      <div className="grid grid-cols-12 gap-8">
        <div className="2xl:col-span-9 xl:col-span-8 col-span-12 h-full max-h-screen overflow-y-auto">
          <Topbar activeItem={SIDEBAR_HELP} showProfileCard />
          <br />
          <Card style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
            <Help onSuccess={handleHelpSuccess} />
          </Card>
        </div>

        <div className="2xl:col-span-3 xl:col-span-4 col-span-12 flex">
          <div className="flex flex-col gap-8">
            {/* Webcam */}
            <Card>
              <WebCam />
            </Card>

            {/* Informasi foto dengan kartu */}
            <Card>
              <PhotoWithCardInformation />
            </Card>
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
}

export default BantuanPage;
