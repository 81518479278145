import { useContext } from 'react';

import UserContext from 'context/user.context';
import Card from 'components/Card';
import useBaseURL from 'hooks/useBaseURL';
import { safeJoinURL } from 'libs/url';
import Typography from 'components/Typography';
import ExamControllerContext from 'context/examController.context';

function ProfileCard() {
  const [user] = useContext(UserContext);
  const [{ isSimulation }] = useContext(ExamControllerContext);
  const baseURL = useBaseURL();

  return (
    <>
      <Card>
        <div className="flex items-center gap-5 pt-5">
          {!isSimulation && (
            <img
              src={safeJoinURL(baseURL.profilePhoto, user.foto)}
              alt="Foto profil"
              className="w-14 h-14 object-cover"
            />
          )}
          <div>
            <Typography className="font-medium">
              {isSimulation ? 'Simulasi' : user.nama}
            </Typography>
            <Typography
              variant="small"
              className="text-gray-400 font-medium mt-1.5"
            >
              {isSimulation ? 'Simulasi' : user.kdr}
            </Typography>
          </div>
        </div>

        <div className="grid grid-flow-col grid-cols-2 gap-3 mt-6">
          <div>
            <Typography
              variant="small"
              className="font-normal text-gray-400"
              gutterBottom
            >
              Kelompok:
            </Typography>
            <Typography className="font-normal">
              {isSimulation ? 'Simulasi' : user.jlr_nama}
            </Typography>
          </div>

          <div>
            <Typography
              variant="small"
              className="font-normal text-gray-400"
              gutterBottom
            >
              Wilayah:
            </Typography>
            <Typography className="font-normal">
              {isSimulation ? 'Simulasi' : user.ptk_nmp}
            </Typography>
          </div>
        </div>
      </Card>
    </>
  );
}

export default ProfileCard;
