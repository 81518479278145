const configuration = {
  PRIMARY_SERVER_URL: 'https://cbtakmi.kemenag.go.id/backend/',
  FIREBASE: {
    apiKey: 'AIzaSyCRyguSlmK8dNOfW4x5eh8EjT5AUkq8RFY',
    authDomain: 'cbt-2021.firebaseapp.com',
    projectId: 'cbt-2021',
    storageBucket: 'cbt-2021.appspot.com',
    messagingSenderId: '1079711360144',
    appId: '1:1079711360144:web:74c9679363d0b5e0ea6415',
    measurementId: 'G-NZF2ENH90Q',
  },
  FIREBASE_SENDER_ID: '1079711360144',

  // Jeda waktu agar tombol refresh dapat ditekan.
  REFRESH_BREAK_TIME: 20, // In seconds
  REFRESH_QUESTION_BREAK_TIME: 20, // In seconds

  SIMULATE_DURATION: 60 * 10, // 10 minutes (dalam satuan detik).
};

export default configuration;
