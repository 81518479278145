/* eslint-disable react/prop-types */
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactTooltip from 'react-tooltip';

import { safeJoinURL } from 'libs/url';
import useBaseURL from 'hooks/useBaseURL';
import clsx from 'libs/clsx';
import { ReactComponent as DrawIcon } from 'assets/icons/draw.svg';
import { ReactComponent as EraserIcon } from 'assets/icons/eraser.svg';
import { ReactComponent as ResetIcon } from 'assets/icons/reset.svg';
import { ReactComponent as DoneIcon } from 'assets/icons/done.svg';

const WRITE = 'WRITE';
const ERASER = 'ERASER';

function ExamDraw(
  { question: { id }, answer, onAnswerChange },
  ref // eslint-disable-line no-unused-vars
) {
  const baseURL = useBaseURL();

  const canvasRef = useRef(null);

  // Indikasi apakah gambar pada canvas diubah.
  const [isCanvasEdited, setIsCanvasEdited] = useState(false);

  // Current mode, write or erase.
  const [mode, setMode] = useState(WRITE);

  // Status apakah peserta sedang menulis/menghapus.
  const [isPainting, setIsPainting] = useState(false);

  // Posisi mouse saat ini, akan berubah saat mouse diklik dan ditahan pada
  // canvas.
  const [mousePosition, setMousePosition] = useState(undefined);

  // Parent element dari canvas, tidak menggunakan ref agar tahu apabila
  // variable sudah memiliki value.
  const [parentCanvasRef, setParentCanvasRef] = useState(undefined);

  // Dimensi dari canvas. Akan berubah saat peserta baru pertama kali membuka
  // jenis soal menggambar. Atau jika peserta berpindah soal dan soal sebelumnya
  // bukan soal menggambar.
  const [fixedCanvasDimension, setFixedCanvasDimension] = useState({
    width: undefined,
    height: undefined,
  });

  const getCoordinates = useCallback(
    (event) => ({
      x: event.layerX,
      y: event.layerY,
    }),
    []
  );

  const exitPaint = useCallback(() => {
    setIsPainting(false);
  }, []);

  // Fungsi yang akan dijalankan ketika mouse sedang diklik dan ditahan.
  const startPaint = useCallback(
    (event) => {
      if (!canvasRef.current) return;

      setIsPainting(true);
      setMousePosition(getCoordinates(event));
    },
    [getCoordinates]
  );

  // Menangani proses penyimpanan gambar.
  const save = useCallback(() => {
    if (!canvasRef.current || !isCanvasEdited) return;

    // Mengubah coretan canvas menjadi gambar.
    const image = canvasRef.current.toDataURL();
    onAnswerChange(image);
    setIsCanvasEdited(false);
  }, [isCanvasEdited, onAnswerChange]);

  // Menggambar garis atau menghapus garis berdasarkan posisi cursor.
  const drawLine = useCallback(
    (originalMousePosition, newMousePosition) => {
      if (!canvasRef.current) return;

      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      if (!context) return;
      if (mode === WRITE) {
        context.strokeStyle = '#00A65A';
        context.lineJoin = 'round';
        // 200 adalah rasio perbandingan lebar canvas dengan ketebalan garis.
        context.lineWidth =
          canvasRef.current.getBoundingClientRect().width / 200;

        context.beginPath();
        context.moveTo(originalMousePosition.x, originalMousePosition.y);
        context.lineTo(newMousePosition.x, newMousePosition.y);
        context.closePath();

        context.stroke();
      } else {
        const { x, y } = originalMousePosition;
        const eraserWidth = 15;

        context.clearRect(x, y, eraserWidth, eraserWidth);
      }
    },
    [mode]
  );

  // Fungsi yang akan dijalankan jika mouse sedang diklik dan cursor sedang
  // berpindah.
  const paint = useCallback(
    (event) => {
      if (isPainting) {
        const newMousePosition = getCoordinates(event);
        if (mousePosition && newMousePosition) {
          setIsCanvasEdited(true);
          drawLine(mousePosition, newMousePosition);
          setMousePosition(newMousePosition);
        }
      }
    },
    [drawLine, getCoordinates, isPainting, mousePosition]
  );

  // Membersihkan canvas.
  const clearCanvas = useCallback(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(
      0,
      0,
      canvas.getBoundingClientRect().width,
      canvas.getBoundingClientRect().height
    );
  }, []);

  // Menangani ketika tombol tulis diklik.
  const handleWriteButtonClick = useCallback(() => {
    setMode(WRITE);
  }, []);

  // Menangani ketika tombol penghapus diklik.
  const handleEraseButtonClick = useCallback(() => {
    setMode(ERASER);
  }, []);

  // Menangani ketika tombol reset diklik.
  const handleResetButtonClick = useCallback(() => {
    if (!canvasRef.current) return;
    clearCanvas();
    setIsCanvasEdited(true);
  }, [clearCanvas]);

  // Me-reset isi canvas saat jawaban direset.
  useEffect(() => {
    if (answer === ' ') clearCanvas();
  }, [answer, clearCanvas]);

  // Me-reset nilai is canvas edited saat berpindah soal.
  useEffect(() => {
    setIsCanvasEdited(false);
  }, [id]);

  // Menangani proses penggambaran ulang coretan pada canvas yang sebelumnya
  // sudah disimpan ke sistem.
  useEffect(() => {
    if (!canvasRef.current) return;

    clearCanvas();

    if (answer === ' ') return;

    const image = new Image();
    image.src = safeJoinURL(baseURL.answerWithImage, answer);
    image.crossOrigin = 'anonymous'; // Handle SecurityError
    image.onload = () => {
      const canvas = canvasRef.current;

      const canvasWidth = canvas.getBoundingClientRect().width;
      const canvasHeight = canvas.getBoundingClientRect().height;

      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvasWidth, canvasHeight);
      context.save();

      // Manaruh gambar dan menyesuaikan ukuran agar dimensi gambar sesuai
      // dengan dimensi canvas.
      if (image.width > image.height) {
        context.drawImage(
          image,
          0,
          0,
          image.width,
          image.height,
          0,
          0,
          canvasWidth,
          canvasHeight
        );
      } else {
        // Melakukan ratate apabila gambar berbentuk potrait.
        context.translate(canvasWidth / 2, canvasHeight / 2);
        context.rotate((-90 * Math.PI) / 180);

        context.drawImage(
          image,
          -canvasHeight / 2,
          -canvasWidth / 2,
          canvasHeight,
          canvasWidth
        );
        context.restore();
      }
    };
    // Mengurangi proses pe-render-an ulang saat variable jawaban ter-update.
  }, [id, baseURL.answerWithImage, clearCanvas, answer]);

  // Menambahkan aksi mousedown.
  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    canvas.addEventListener('mousedown', startPaint);

    // eslint-disable-next-line consistent-return
    return () => {
      canvas.removeEventListener('mousedown', startPaint);
    };
  }, [startPaint]);

  // Menambahkan aksi mousemove.
  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    canvas.addEventListener('mousemove', paint);

    // eslint-disable-next-line consistent-return
    return () => {
      canvas.removeEventListener('mousemove', paint);
    };
  }, [paint]);

  // Menambahkan aksi mouseup dan mouseleave.
  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    canvas.addEventListener('mouseup', exitPaint);
    canvas.addEventListener('mouseleave', exitPaint);

    // eslint-disable-next-line consistent-return
    return () => {
      canvas.removeEventListener('mouseup', exitPaint);
      canvas.removeEventListener('mouseleave', exitPaint);
    };
  }, [exitPaint]);

  // Menangani perubahan lebar dan tinggi canvas pada saat variable
  // parentCanvasRef memiliki nilai.
  useEffect(() => {
    const hasParentCanvasRef = typeof parentCanvasRef !== 'undefined';
    const isCanvasDimensionDefined =
      typeof fixedCanvasDimension.width !== 'undefined' &&
      typeof fixedCanvasDimension.height !== 'undefined';

    if (!hasParentCanvasRef || isCanvasDimensionDefined) return;

    const newWidth = parentCanvasRef.getBoundingClientRect().width - 5;
    const newHeight = (9 / 16) * newWidth;

    setFixedCanvasDimension({ width: newWidth, height: newHeight });
  }, [
    fixedCanvasDimension.height,
    fixedCanvasDimension.width,
    parentCanvasRef,
  ]);

  return (
    <div className="relative">
      {/* Placeholder pada canvas */}
      {answer === ' ' && (
        <div
          className="absolute text-4xl text-center text-gray-300 transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 select-none pointer-events-none"
          style={{ fontFamily: 'HEY JUNE' }}
        >
          Gambar Di Sini
        </div>
      )}

      <div className="overflow-x-auto">
        {/* Canvas */}
        <div ref={setParentCanvasRef} className="relative">
          <canvas
            ref={canvasRef}
            className={clsx('border-2 mx-auto block')}
            width={fixedCanvasDimension.width || 0}
            height={fixedCanvasDimension.height || 0}
            tabIndex={0}
            style={{
              cursor:
                mode === WRITE
                  ? `url(./assets/brush.svg), auto`
                  : `url(./assets/eraser.svg), auto`,
            }}
          />

          {/* Action buttons */}
          <div
            className={clsx(
              'absolute top-2 right-2',
              isPainting && 'pointer-events-none opacity-25'
            )}
          >
            <div className="flex gap-2">
              {/* Pencil button */}
              <DrawIcon
                role="button"
                onClick={handleWriteButtonClick}
                data-tip="Tulis"
              />
              <ReactTooltip />

              {/* Eraser button */}
              <EraserIcon
                role="button"
                onClick={handleEraseButtonClick}
                data-tip="Hapus"
              />
              <ReactTooltip />

              {/* Reset button */}
              <ResetIcon
                role="button"
                onClick={handleResetButtonClick}
                data-tip="Reset"
              />
              <ReactTooltip />

              {/* Done button */}
              <DoneIcon
                role="button"
                className={clsx(!isCanvasEdited && 'cursor-not-allowed')}
                onClick={save}
                data-tip={isCanvasEdited ? 'Simpan' : 'Jawaban telah tersimpan'}
              />
              <ReactTooltip />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ExamDraw = forwardRef(ExamDraw);

export default memo(ExamDraw);
