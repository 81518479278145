import { useContext, useCallback, useEffect } from 'react';

import api from 'api';
import AppContext from 'context/app.context';
import { handleHttpError } from 'utils/httpError';
import { setConfig, unsetBackendURL } from 'actions/app.action';
import HttpRequest from 'utils/httpRequest';

/**
 * @description
 * Call this hook only once in the project, because it's this hook has some
 * side effects.
 */
export default function useConfig() {
  const [{ backendURL }, dispatchApp] = useContext(AppContext);

  const fetchConfig = useCallback(async () => {
    try {
      const response = await new HttpRequest(api.config.get()).call();
      dispatchApp(setConfig(response.data.data));
    } catch (err) {
      handleHttpError(err);

      dispatchApp(unsetBackendURL());
    }
  }, [dispatchApp]);

  useEffect(() => {
    if (!backendURL) return;
    fetchConfig();
  }, [backendURL, fetchConfig]);
}
