import { SET_SELFIE_PHOTO, UNSET_PHOTOS, UNSET_SELFIE_PHOTO } from 'constant';

export const initialPhotoState = {
  selfie: undefined,
};

export default function photoReducer(state = initialPhotoState, action) {
  switch (action.type) {
    case SET_SELFIE_PHOTO: {
      return { ...state, selfie: action.payload };
    }
    case UNSET_SELFIE_PHOTO: {
      return { ...state, selfie: null };
    }
    case UNSET_PHOTOS: {
      return initialPhotoState;
    }
    default: {
      console.error(`Unhandled action type: ${action.type}`);
      return state;
    }
  }
}
