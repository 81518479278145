/* eslint-disable react/prop-types */
import { forwardRef, memo, useEffect, useState } from 'react';

import Input from 'components/Input';

function ExamFill(
  { question: { id }, answer, onAnswerChange },
  ref // eslint-disable-line no-unused-vars
) {
  // Isi dari textarea.
  const [value, setValue] = useState(answer === ' ' ? '' : answer);

  // Get new answer.
  const getAnswer = () => (value === '' ? ' ' : value);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const isEdited = getAnswer() !== answer;

  // Mengubah jawaban saat ada event onblur.
  const handleBlur = () => {
    if (isEdited) onAnswerChange(value);
  };

  // Mereset value apabila jawabannya direset.
  useEffect(() => {
    if (answer === ' ') setValue('');
  }, [answer]);

  // Men-set default value berdasarkan jawaban yang sudah dijawab.
  useEffect(() => {
    setValue(answer === ' ' ? '' : answer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div>
      <Input
        as="textarea"
        id={`fill-${id}`}
        name="fill"
        label="Jawaban"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        className="mb-5"
        rows={10}
      />
    </div>
  );
}

ExamFill = forwardRef(ExamFill);

export default memo(ExamFill);
