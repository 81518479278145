import { Formik } from 'formik';
import propTypes from 'prop-types';
import { useMemo } from 'react';

import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';

function HelpForm({ onSubmit }) {
  const helpOptions = useMemo(
    () => [
      { value: 1, text: 'Masalah login' },
      { value: 2, text: 'Masalah koneksi' },
      { value: 3, text: 'Masalah tiba-tiba berhenti' },
      { value: 4, text: 'Lainnya' },
    ],
    []
  );

  return (
    <Formik initialValues={{ category: 1, question: '' }} onSubmit={onSubmit}>
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Select
            id="category"
            name="category"
            value={values.category}
            onChange={handleChange}
            label="Kategori"
            options={helpOptions}
            error={errors.category}
            disabled={isSubmitting}
          />

          <div className="mt-4">
            <Input
              as="textarea"
              name="question"
              id="question"
              label="Permasalahan"
              value={values.question}
              onChange={handleChange}
              disabled={isSubmitting}
              error={errors.question}
              rows={7}
            />
          </div>

          <div className="mt-5">
            <Button
              color="danger"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              block
            >
              Lapor
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}

HelpForm.propTypes = {
  onSubmit: propTypes.func.isRequired,
};

export default HelpForm;
