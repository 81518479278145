import { useContext } from 'react';
import propTypes from 'prop-types';

import Button from 'components/Button';
import Alert from 'components/Alert';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
} from 'components/Modal';
import { safeJoinURL } from 'libs/url';
import useBaseURL from 'hooks/useBaseURL';
import PhotoContext from 'context/photo.context';

function ShowPhotoWithCardModal({ isOpen, onClose }) {
  const path = useBaseURL();
  const [photo] = useContext(PhotoContext);

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>Foto Dengan Kartu</ModalHeader>
      <ModalContent>
        {photo.selfie ? (
          <div className="mt-3">
            <img
              className="w-full"
              alt="Foto dengan kartu"
              src={safeJoinURL(path.selfiePhoto, photo.selfie)}
            />
          </div>
        ) : (
          <Alert color="warning">
            Anda belum mengunggah foto dengan kartu.
          </Alert>
        )}
      </ModalContent>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Kembali
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ShowPhotoWithCardModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default ShowPhotoWithCardModal;
