/* eslint-disable no-const-assign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import propTypes from 'prop-types';
import { Popover } from 'react-tiny-popover';

import * as examHelper from 'utils/exam';
import clsx from 'libs/clsx';
import Typography from 'components/Typography';
import { safeJoinURL } from 'libs/url';
import useBaseURL from 'hooks/useBaseURL';
import Popup from 'components/Exam/ExamPopup/Popup';

function Container({
  isOpen,
  onOpen,
  onClose,
  answer,
  choices,
  onAnswerChange,
}) {
  const path = useBaseURL();

  // Menutup popover saat menjawab.
  const handleAnswerChange = (...args) => {
    onAnswerChange(...args);
    onClose();
  };

  const togglePopover = () => (isOpen ? onClose() : onOpen());

  return (
    <div className="inline-block">
      <Popover
        isOpen={isOpen}
        positions={['bottom', 'left', 'top', 'right']}
        padding={10}
        onClickOutside={onClose}
        content={({ position, childRect, popoverRect }) => (
          <Popup
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            choices={choices}
            onAnswerChange={handleAnswerChange}
          />
        )}
      >
        {examHelper.isImage(answer) ? (
          <img
            className="h-full max-w-12 max-h-24 select-none cursor-pointer inline-block bg-blue-100 p-1 rounded-lg border-dashed border-2 border-blue-500"
            src={safeJoinURL(path.questionImage, answer)}
            alt={answer}
            onClick={togglePopover}
          />
        ) : (
          <Typography
            as="div"
            className={clsx(
              'select-none cursor-pointer inline-block bg-blue-100 p-1 rounded-lg border-dashed border-2 border-blue-500',
              answer ? 'font-bold' : 'text-gray-500',
              /[\u0600-\u06FF]/.test(answer) && 'arabic'
            )}
            dangerouslySetInnerHTML={{ __html: answer || 'Pilih jawaban' }}
            onClick={togglePopover}
          />
        )}
      </Popover>
    </div>
  );
}

Container.defaultProps = {
  answer: null,
};

Container.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onOpen: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  answer: propTypes.string,
  choices: propTypes.arrayOf(propTypes.string).isRequired,
  onAnswerChange: propTypes.func.isRequired,
};

export default Container;
