import create from 'zustand';

import configuration from 'configuration';
import { SECOND } from 'constant';

// Local component state untuk memanajemen tombol refresh.
export default create((set) => ({
  // Jika true maka diperboleh me-refresh.
  status: true,
  // Sisa waktu agar tombol refresh dapat diklik.
  willEnabledOn: 0,
  // interval untuk mengurangi nilai dari willEnableOn.
  interval: undefined,
  // Timeout membuat status can refresh menjadi true.
  timeout: undefined,

  // Mengizinkan refresh kembali setelah waktu yang sudah ditentukan.
  enableBackByTimeout: () =>
    set((state) => {
      clearInterval(state.interval);
      clearTimeout(state.timeout);

      return {
        status: false,
        willEnabledOn: configuration.REFRESH_BREAK_TIME,

        // Mengatur pengurangan willEnabledOn secara live sampai menyentuh angka
        // nol.
        interval: setInterval(
          () =>
            set((_state) => {
              if (_state.willEnabledOn === 0) {
                return clearInterval(_state.interval);
              }

              return {
                willEnabledOn: _state.willEnabledOn - 1,
              };
            }),
          1 * SECOND
        ),
        // Mengubah status menjadi true berdasarkan detik yang sudah ditentukan.
        timeout: setTimeout(
          () => set(() => ({ status: true })),
          configuration.REFRESH_BREAK_TIME * SECOND
        ),
      };
    }),
}));
