import { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';

import Button from 'components/Button';
import { Modal, ModalContent, ModalHeader } from 'components/Modal';
import CloseAppButton from 'components/Helpers/CloseAppButton';
import configuration from 'configuration';
import { checkDomain } from 'libs/url';
import Alert from 'components/Alert';
import { SECOND } from 'constant';

function SelectServerModal({ isOpen, onChange }) {
  const errorTimeoutRef = useRef();

  // List dari server.
  const [servers, setServers] = useState([]);

  const [loading, setLoading] = useState('');
  const [error, setError] = useState('');

  // Handle server button click.
  const handleClick = async (domain) => {
    setLoading(domain);
    setError('');

    try {
      await checkDomain(domain);
      onChange(domain);
    } catch (err) {
      setError('Domain sedang tidak aktif.');
    }

    setLoading('');
  };

  const fetchListServer = () => {
    axios
      .create({ baseURL: configuration.PRIMARY_SERVER_URL })
      .get('/api/server')
      .then((res) => setServers(res.data.data));
  };

  // Menghilangkan pesan error setelah 3 detik.
  useEffect(() => {
    if (error) {
      errorTimeoutRef.current = setTimeout(() => setError(''), 3 * SECOND);
      fetchListServer();
    }
    return () => clearTimeout(errorTimeoutRef.current);
  }, [error]);

  // Mendapatkan data list server.
  useEffect(() => {
    fetchListServer();
  }, []);

  return (
    <Modal isOpen={isOpen} size="sm">
      <ModalHeader>Pilih Server</ModalHeader>

      <ModalContent>
        {/* Error message */}
        {error && (
          <Alert color="danger" className="mb-5">
            {error}
          </Alert>
        )}

        {/* Available server */}
        <div className="flex flex-col gap-5">
          {servers.map((server, key) => (
            <Button
              key={key}
              type="button"
              onClick={() => handleClick(server.url)}
              className="break-words"
              loading={server.url === loading}
              disabled={server.status === 0 || !!loading} // 0 is inactive
              block
            >
              {server.nama} ({server.url})
            </Button>
          ))}
        </div>
      </ModalContent>

      <CloseAppButton />
    </Modal>
  );
}

SelectServerModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onChange: propTypes.func.isRequired,
};

export default SelectServerModal;
