import axios from 'axios';

/**
 * Menambahkan token pada Header Authorization.
 *
 * @param {String} token token dari request
 */
export function setAuthorizationToken(token = null) {
  localStorage.setItem('token', token);
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function unsetAuthorizationToken() {
  try {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
  } catch {
    /** */
  }
}

export function setExamStageHeader(stage) {
  axios.defaults.headers.common['X-EXAM-STAGE'] = stage;
}

export function unsetExamStageHeader() {
  try {
    delete axios.defaults.headers.common['X-EXAM-STAGE'];
  } catch {
    /** */
  }
}
