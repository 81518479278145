/* eslint-disable react/prop-types */
import { Formik } from 'formik';
import { FaUser } from 'react-icons/fa';
import MaskedInput from 'react-text-mask';

import Button from 'components/Button';
import Input from 'components/Input';

function LoginWithUsername({ onSubmit, onClose }) {
  return (
    <Formik initialValues={{ username: '' }} onSubmit={onSubmit}>
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MaskedInput
            mask={[
              /[0-9]/,
              /[0-9]/,
              '.',
              /[0-9]/,
              /[0-9]/,
              '.',
              /[0-9]/,
              /[0-9]/,
              '.',
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              '.',
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
            ]}
            type="text"
            name="username"
            id="help-username"
            placeholder="Kode pendaftaran"
            value={values.username}
            onChange={handleChange}
            disabled={isSubmitting}
            autoFocus
            render={(ref, props) => (
              <Input
                ref={(input) => ref(input)}
                label="Username:"
                icon={<FaUser />}
                error={errors.username}
                {...props}
              />
            )}
          />

          <div className="text-right mt-5">
            <Button
              type="submit"
              color="danger"
              loading={isSubmitting}
              disabled={isSubmitting}
              block
            >
              Buat Laporan
            </Button>
            <Button
              type="button"
              color="secondary"
              className="mt-3"
              onClick={onClose}
              disabled={isSubmitting}
              block
            >
              Kembali
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default LoginWithUsername;
