import axios from 'axios';
import Promise from 'bluebird';

import { SECOND } from 'constant';

export function safeJoinURL(...args) {
  if (args.some((arg) => typeof arg !== 'string')) return null;
  return args.map((arg) => arg.replace(/^\/+|\/+$/g, '')).join('/');
}

export function checkDomain(domain) {
  return new Promise((resolve, reject) =>
    axios
      .create({ baseURL: domain, timeout: 5 * SECOND })
      .get('/check')
      .then((res) => {
        if (/^ok$/i.test(res.data)) return resolve(domain);
        return reject();
      })
      .catch(reject)
  );
}
