/* eslint-disable react/prop-types */
import { Fragment, memo } from 'react';

import clsx from 'libs/clsx';
import Typography from 'components/Typography';

function ExamQuestionNumbers({
  currentQuestionIndex,
  groupedQuestions,
  answers,
  onNumberButtonClick,
}) {
  return (
    <div className="relative inset-0 h-full max-h-full">
      <div className="grid grid-cols-5 gap-3 pr-2">
        {/* List nomor dari pertanyaan */}
        {groupedQuestions.map((questions, index) => {
          const areAllQuestionsAnswered = questions.every(
            (q) => answers[q.index] !== ' '
          );

          const areAllQuestionsUnanswered = questions.every(
            (q) => answers[q.index] === ' '
          );

          const isCurrentQuestion = index === currentQuestionIndex;

          const isFirst = index === 0;
          const isSubjectMatterSameWithPrev =
            index > 0 &&
            groupedQuestions[index - 1][0]?.subjectMatter ===
              questions[0].subjectMatter;

          // Tombol nomor masing-masing pertanyaan.
          return (
            <Fragment key={index}>
              {/* Nama materi */}
              {isFirst && (
                <div className="col-span-5">{questions[0].subjectMatter}</div>
              )}

              {/* Nama materi */}
              {!isFirst && !isSubjectMatterSameWithPrev && (
                <div className="col-span-5 pt-1">
                  {questions[0].subjectMatter}
                </div>
              )}

              {/* Number button */}
              <button
                type="button"
                onClick={() => onNumberButtonClick(index)}
                className={clsx(
                  'p-1 rounded-md border-2 text-center relative',
                  areAllQuestionsAnswered &&
                    isCurrentQuestion &&
                    'bg-green-100 text-green-700 border-green-500',
                  areAllQuestionsAnswered &&
                    !isCurrentQuestion &&
                    'bg-green-500 text-white border-green-500',
                  areAllQuestionsUnanswered &&
                    isCurrentQuestion &&
                    'bg-white text-black border-green-400',
                  areAllQuestionsUnanswered &&
                    !isCurrentQuestion &&
                    'bg-gray-100 text-gray-500 border-gray-100',
                  !areAllQuestionsAnswered &&
                    !areAllQuestionsUnanswered &&
                    isCurrentQuestion &&
                    'bg-yellow-200 text-yellow-600 border-yellow-500',
                  !areAllQuestionsAnswered &&
                    !areAllQuestionsUnanswered &&
                    !isCurrentQuestion &&
                    'bg-yellow-500 text-white border-yellow-500'
                )}
              >
                {/* Question number */}
                <Typography as="span" className="font-normal">
                  {index + 1}
                </Typography>

                {/* Number button's badge */}
                {/* {questions.length === 1 &&
                  ['pil', 'mlt', 'cck', 'sjt', 'lik'].includes(
                    questions[0].type
                  ) &&
                  areAllQuestionsAnswered && (
                    <span className="absolute -top-3 -right-2 bg-white text-gray-700 px-1 text-xs rounded-full border-2 border-gray-300 font-semibold">
                      {answers[questions[0].index].replace(' ', '_')}
                    </span>
                  )} */}
              </button>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default memo(ExamQuestionNumbers);
