import propTypes from 'prop-types';

import clsx from 'libs/clsx';
import Typography from 'components/Typography';

const MODAL_SIZES = {
  xs: 'w-full max-w-md',
  sm: 'w-full max-w-xl',
  md: 'w-full max-w-3xl',
  lg: 'w-full max-w-5xl',
  xl: 'w-full max-w-7xl',
};

export function ModalHeader({ children }) {
  return (
    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
      <Typography variant="subtitle2" className="font-semibold">
        {children}
      </Typography>
    </div>
  );
}

export function ModalContent({ className, children }) {
  return (
    <div className={clsx('relative p-6 flex-auto', className)}>{children}</div>
  );
}

export function ModalFooter({ children }) {
  return (
    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
      {children}
    </div>
  );
}

export function Modal({ isOpen, size, children }) {
  return isOpen ? (
    <div className="fixed inset-0 z-50">
      <div className="opacity-25 absolute inset-0 bg-black" />
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
        <div
          className={clsx(
            'relative w-auto my-6 mx-auto px-10',
            MODAL_SIZES[size]
          )}
        >
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {children}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

Modal.defaultProps = {
  isOpen: false,
  size: 'md',
};

ModalContent.defaultProps = {
  className: '',
};

ModalHeader.propTypes = {
  children: propTypes.node.isRequired,
};

ModalContent.propTypes = {
  children: propTypes.node.isRequired,
  className: propTypes.string,
};

ModalFooter.propTypes = {
  children: propTypes.node.isRequired,
};

Modal.propTypes = {
  isOpen: propTypes.bool,
  size: propTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  children: propTypes.node.isRequired,
};

export default Modal;
