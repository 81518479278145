/* eslint-disable react/prop-types */
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Modal, ModalContent, ModalHeader } from 'components/Modal';
// import Progress from 'components/Progress';
import ExamContext from 'context/exam.context';
import * as examHelper from 'utils/exam';
import Button from 'components/Button';
import Typography from 'components/Typography';
import { ReactComponent as AstronautIcon } from 'assets/illustrations/astronaut.svg';
import { secondsToTimerText } from 'libs/time';
import { EXAM_PACKET_STARTED, SECOND } from 'constant';
// import PhotoContext from 'context/photo.context';
// import Alert from 'components/Alert';
// import { showPhotoWithCardEvent } from 'utils/events';

function StartExamConfirmation({ examId, onConfirm, onCancel }) {
  const [exams] = useContext(ExamContext);
  // const [photo] = useContext(PhotoContext);

  const [loading, setLoading] = useState(false);

  // Agar loading otomatis berhenti setelah beberapa detik.
  const loadingTimeout = useRef(false);

  // const hasPhotoWithCard = !!photo.selfie;

  const exam = useMemo(() => {
    if (!examId) return null;

    const filteredExam = exams.filter(
      (_exam) => _exam.paket_ujian.idUj === examId
    )[0];

    if (!filteredExam) return null;

    const isStarted = EXAM_PACKET_STARTED.includes(
      filteredExam.paket_ujian.sta
    );
    const remainingTime = secondsToTimerText(
      isStarted
        ? examHelper.getRemainingTime(filteredExam)
        : filteredExam.ujian.drs
    );

    return {
      name: filteredExam.paket_soal.dsk,
      status: examHelper.getStatus(filteredExam),
      remainingTime,
      isStarted,
      // questionTotal: filteredExam.paket_soal.idSl.length,
      questionAnsweredTotal: (filteredExam.paket_ujian.wtj || []).filter(
        (t) => t !== 0
      ).length,
      percentage: examHelper.getPercentage(filteredExam),
    };
  }, [examId, exams]);

  // const handleUploadPhotoWithCard = () => {
  //   window.dispatchEvent(showPhotoWithCardEvent);
  //   onCancel();
  // };

  const handleConfirmClick = () => {
    clearTimeout(loadingTimeout.current);
    loadingTimeout.current = setTimeout(() => setLoading(false), 5 * SECOND);
    setLoading(true);

    onConfirm();
  };

  useEffect(() => () => clearTimeout(loadingTimeout.current), []);

  if (!exam) return null;

  return (
    <Modal isOpen>
      <ModalHeader>{exam.name}</ModalHeader>
      <ModalContent className="text-center">
        <div>
          <Typography gutterBottom>Sisa Waktu Tersisa</Typography>
          <Typography variant="heading3" gutterBottom>
            {exam.remainingTime}
          </Typography>
        </div>

        <AstronautIcon className="mx-auto mb-6 h-auto 2xl:w-80 xl:w-48 w-40" />

        <Typography
          color="dark"
          variant="subtitle1"
          className="font-medium"
          gutterBottom
        >
          Ujian akan dimulai
        </Typography>
        <Typography color="muted" gutterBottom>
          Klik tombol <strong>pertanyaan pertama</strong> untuk melihat
          pertanyaan yang pertama, dan waktu Anda akan segera dihitung. Saat
          proses ujian berlangsung, kamera akan menyala dan gerakan Anda akan
          diawasi oleh pengawas.
        </Typography>

        {/* Peintah untuk mengunggah foto dengan kartu */}
        {/* {!hasPhotoWithCard && (
          <Alert color="danger">
            Anda belum mengunggah foto dengan kartu saat ini.
            <button
              type="button"
              className="font-semibold underline text-red-800"
              onClick={handleUploadPhotoWithCard}
            >
              Unggah sekarang.
            </button>
          </Alert>
        )} */}

        {/* Start exam button */}
        <Button
          onClick={handleConfirmClick}
          color="primary"
          className="mt-10"
          loading={loading}
          // disabled={loading}
          disabled={loading}
          block
        >
          Pertanyaan Pertama
        </Button>

        {/* Back button */}
        <Button onClick={onCancel} color="secondary" className="mt-3" block>
          Kembali
        </Button>

        {/* <Progress percentage={exam.percentage} className="mt-10" /> */}
        {/* <Typography variant="small" className="mt-3" gutterBottom>
          {exam.isStarted ? (
            <>
              Terjawab {exam.questionAnsweredTotal} dari {exam.questionTotal} (
              {exam.percentage}%)
            </>
          ) : (
            <>Terjawab 0 dari {exam.questionTotal} (0%)</>
          )}
        </Typography> */}
      </ModalContent>
    </Modal>
  );
}

export default StartExamConfirmation;
