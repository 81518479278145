import { useCallback, useContext } from 'react';

import PermissionContext from 'context/permission.context';
import {
  setCameraPermission,
  setMicrophonePermission,
  setNotificationPermission,
} from 'actions/permission.action';

export default function usePermissionHandler() {
  const [, dispatchPermission] = useContext(PermissionContext);

  const requestCameraPermission = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getVideoTracks().forEach((track) => track.stop());
      dispatchPermission(setCameraPermission(true));
    } catch (err) {
      dispatchPermission(setCameraPermission(false));
    }
  }, [dispatchPermission]);

  const requestMicrophonePermission = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getAudioTracks().forEach((track) => track.stop());
      dispatchPermission(setMicrophonePermission(true));
    } catch (err) {
      dispatchPermission(setMicrophonePermission(false));
    }
  }, [dispatchPermission]);

  const requestNotificationPermission = useCallback(async () => {
    try {
      const result = await Notification.requestPermission();
      if (result === 'granted') {
        dispatchPermission(setNotificationPermission(true));
      } else {
        throw new Error('Permission denied');
      }
    } catch (err) {
      dispatchPermission(setNotificationPermission(false));
    }
  }, [dispatchPermission]);

  return {
    requestAllPermissions: () => {
      requestCameraPermission();
      requestMicrophonePermission();
      requestNotificationPermission();
    },
    requestCameraPermission,
    requestMicrophonePermission,
    requestNotificationPermission,
  };
}
