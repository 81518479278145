/* eslint-disable react/prop-types */

/**
 * @typedef Option
 * @type {object}
 * @property {string} value
 * @property {string} text
 */

import { forwardRef, useCallback, useMemo } from 'react';
import colors from 'tailwindcss/colors';
import { MathJax } from 'better-react-mathjax';

import Select from 'components/Select';
import { getTrueByIndex } from 'utils/exam';
// import { getAlphabetByIndex } from 'utils/exam';
// import * as examHelper from 'utils/exam';
import useBaseURL from 'hooks/useBaseURL';
// import { safeJoinURL } from 'libs/url';

function ExamMatch(
  { question: { id, choices, additional }, answer, onAnswerChange },
  ref // eslint-disable-line no-unused-vars
) {
  const path = useBaseURL();

  /**
   * Menampung list sub pertanyaan.
   * @type {Array<string>}
   */
  const subQuestions = useMemo(
    () => Object.entries(additional).map(([, value]) => value),
    [additional]
  );

  /**
   * Menampung list dari jawaban peserta yang sudah dikelola.
   * @type {Array<string>}
   */
  const serializedAnswer = useMemo(() => {
    if (answer === ' ') return subQuestions.map(() => ' ');
    return subQuestions.map((value, index) => answer[index] || ' ');
  }, [answer, subQuestions]);

  /**
   * Menampung list pilihan jawaban yang tersedia.
   * @type {Array<Option>}
   */
  const options = useMemo(() => {
    const defaultOption = [{ value: ' ', text: '--- Pilih ---' }];
    return defaultOption.concat(
      choices.map((value, index) => ({
        value: getTrueByIndex(index),
        text: `${value}`,
      }))
    );
  }, [choices]);

  const handleChange = useCallback(
    (index, value) => {
      const newSerializedAnswer = serializedAnswer.slice();
      newSerializedAnswer[index] = value;
      const newAnswer = newSerializedAnswer.join('');

      // Semua jawaban masih kosong.
      const areAnswersEmpty = /^\s+$/i.test(newAnswer);
      onAnswerChange(areAnswersEmpty ? ' ' : newAnswer);
    },
    [onAnswerChange, serializedAnswer]
  );

  return (
    <>
      {subQuestions.map((subQuestion, index) => {
        const choiceId = `choice-${id}-${index}`;
        const questionArabic = /[\u0600-\u06FF]/.test(options.text);

        const tempChoiceString = subQuestion.replace(
          /@url\//g,
          `${path.questionImage}c/`
        );

        return (
          <div key={index} className="mb-4" id="answer-container">
            <div dir="auto">
              <MathJax>
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: tempChoiceString }}
                />
              </MathJax>
            </div>
            <Select
              id={choiceId}
              name={choiceId}
              className={questionArabic && 'arabic'}
              value={serializedAnswer[index]}
              onChange={(e) => handleChange(index, e.target.value)}
              label=""
              options={options}
            />
          </div>
        );
      })}

      <style jsx="true">{`
        #answer-container label {
          color: ${colors.gray['800']};
          font-weight: 400;
        }
      `}</style>

      <style jsx="true">{`
        #answer-container select {
          font-family: 'Verdana';
          font-size: '1rem';
        }

        #answer-container select.arabic {
          font-family: 'Traditional Arabic';
          font-size: '2rem';
        }
      `}</style>
    </>
  );
}

ExamMatch = forwardRef(ExamMatch);

export default ExamMatch;
