import propTypes from 'prop-types';
import React from 'react';

import Label from './Label';

import clsx from 'libs/clsx';

const Input = React.forwardRef(
  ({ as, label, id, icon, className, error, disabled, ...props }, ref) => (
    <span>
      {label !== '' ? (
        <Label htmlFor={id} error={error}>
          {label}
        </Label>
      ) : (
        ''
      )}
      {as === 'input' ? (
        <input
          ref={ref}
          id={id}
          className={clsx(
            'bg-white text-sm appearance-none border-2 border-gray-200 rounded-md py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500',
            error && 'border-red-500 bg-red-100',
            disabled && 'bg-gray-100 cursor-not-allowed',
            className
          )}
          disabled={disabled}
          {...props}
        />
      ) : (
        <textarea
          ref={ref}
          id={id}
          className={clsx(
            'bg-white text-sm appearance-none border-2 border-gray-200 rounded-md py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500',
            error && 'border-red-500 bg-red-100',
            disabled && 'bg-gray-100 cursor-not-allowed',
            className
          )}
          disabled={disabled}
          {...props}
        />
      )}
      <span
        className="pointer-events-none leading-snug font-normal absolute text-center text-gray-300 bg-transparent items-center right-3 top-1/2"
        style={{ transform: 'translate(0, -50%)' }}
      >
        {icon}
      </span>

      {error && <p className="text-red-500 text-xs italic mt-1">{error}</p>}
    </span>
  )
);

Input.defaultProps = {
  as: 'input',
  icon: null,
  className: '',
  error: null,
  disabled: false,
};

Input.propTypes = {
  as: propTypes.oneOf(['input', 'textarea']),
  label: propTypes.string.isRequired,
  id: propTypes.string.isRequired,
  icon: propTypes.node,
  className: propTypes.string,
  error: propTypes.string,
  disabled: propTypes.bool,
};

export default Input;
