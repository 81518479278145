import propTypes from 'prop-types';

import clsx from 'libs/clsx';

const COLORS = {
  primary: 'bg-primary hover:bg-green-600 text-white',
  danger: 'bg-danger hover:bg-red-700 text-white',
  warning: 'bg-warning hover:bg-yellow-600 text-white',
  info: 'bg-info hover:bg-blue-400 text-white',
  secondary: 'bg-gray-300 hover:bg-gray-400 text-dark',
  'primary-outlined':
    'bg-transparent hover:bg-green-100 text-green-600 border-2 border-green-600 shadow-none',
  'danger-outlined':
    'bg-transparent hover:bg-red-100 text-red-500 border border-red-500 shadow-none',
};

const SIZES = {
  sm: 'py-2 px-4 2xl:text-sm text-xs',
  md: 'py-3 px-6 2xl:text-base text-sm',
  custom: '2xl:py-3 2xl:px-6 2xl:text-base py-2 px-4 text-sm',
};

// eslint-disable-next-line react/prop-types
const Spinner = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    className={clsx(
      'animate-spin m-0 transparent',
      size === 'sm' ? 'w-4 h-4' : 'w-5 h-5'
    )}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#666"
      strokeWidth="10"
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
    />
  </svg>
);
function Button({
  block,
  children,
  color,
  className,
  disabled,
  loading,
  size,
  type,
  ...props
}) {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={clsx(
        'font-semibold rounded-md shadow-md flex gap-2 justify-center items-center',
        SIZES[size],
        disabled
          ? 'bg-gray-300 hover:bg-gray-400 text-gray-600 cursor-not-allowed'
          : COLORS[color],
        block && 'block w-full',
        className
      )}
      {...props}
      disabled={disabled}
    >
      {loading && <Spinner size={size} />}
      {children}
    </button>
  );
}

Button.defaultProps = {
  block: false,
  className: '',
  color: 'primary',
  disabled: false,
  loading: false,
  size: 'md',
  type: 'button',
};

Button.propTypes = {
  block: propTypes.bool,
  className: propTypes.string,
  disabled: propTypes.bool,
  loading: propTypes.bool,
  children: propTypes.node.isRequired,
  color: propTypes.oneOf(Object.entries(COLORS).map(([k]) => k)),
  size: propTypes.oneOf(Object.entries(SIZES).map(([k]) => k)),
  type: propTypes.oneOf(['button', 'submit', 'reset']),
};

export default Button;
