import { isElectron } from 'utils/electron';

export default function registerServiceWorker() {
  if (isElectron()) return;

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('firebase-messaging-sw.js')
      .then((registration) =>
        console.info('Registration successful, scope is:', registration.scope)
      )
      .catch((err) =>
        console.error('Service worker registration failed, error:', err)
      );
  }
}
