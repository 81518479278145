import { useDroppable } from '@dnd-kit/core';
import propTypes from 'prop-types';

import Container from './Container';

function Droppable({ id }) {
  const { setNodeRef } = useDroppable({ id });

  return (
    <Container
      ref={setNodeRef}
      className="bg-gray-100 border-4 border-dashed border-gray-300"
    />
  );
}

export function DroppableChoiceContainer({ id, children }) {
  const { setNodeRef } = useDroppable({ id });

  return <div ref={setNodeRef}>{children}</div>;
}

Droppable.propTypes = {
  id: propTypes.string.isRequired,
};

DroppableChoiceContainer.propTypes = {
  id: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
};

export default Droppable;
