import popup from './popup';
import { examIsOverEvent, stageChangedEvent } from './events';

import { EXAM_IS_OVER, SECOND, STAGE_CHANGED } from 'constant';

/**
 * Class error yang digunakan untuk menangani logout paksa.
 */
export class ForceLogoutError extends Error {
  constructor(message = '') {
    super(message);
    this.name = 'ForceLogout';
  }
}

export function isExamOverError(err) {
  return (
    err?.response?.status === 400 &&
    // eslint-disable-next-line no-underscore-dangle
    err?.response?.data?.errors?._type === EXAM_IS_OVER
  );
}

export function isStageChangedError(err) {
  return (
    err?.response?.status === 400 &&
    // eslint-disable-next-line no-underscore-dangle
    err?.response?.data?.errors?._type === STAGE_CHANGED
  );
}

// eslint-disable-next-line import/prefer-default-export
export function handleHttpError(err) {
  let title = 'Ada kesalahan';
  let text = '';

  if (isExamOverError(err)) {
    window.dispatchEvent(examIsOverEvent);
    return;
  }

  if (isStageChangedError(err)) {
    // eslint-disable-next-line no-underscore-dangle
    stageChangedEvent.value = err.response.data.errors._new_stage;
    window.dispatchEvent(stageChangedEvent);
    return;
  }

  if (err instanceof ForceLogoutError) {
    title = '';
    text = 'Sesi login Anda telah habis, silahkan login kembali';
  } else {
    switch (err?.response?.status) {
      case 400:
        title = 'Validasi gagal';
        text = Object.entries(err.response.data.errors)
          .map(([key, value]) => (/^_+/.test(key) ? null : value))
          .filter((value) => value !== null)
          .join(', ');
        break;
      case 500:
        text = 'Internal Server Error';
        break;
      case 408:
      default:
        text = 'Periksa koneksi internet Anda';
        break;
    }
  }

  popup.showErrorWithTimer(title, text, 3 * SECOND);
}
