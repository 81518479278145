import { useContext } from 'react';

import AppContext from 'context/app.context';

/**
 * @typedef {Object} Path
 * @property {string|undefined} backendURL
 * @property {string|undefined} profilePhoto
 * @property {string|undefined} selfiePhoto
 * @property {string|undefined} answerWithAudio
 * @property {string|undefined} answerWithImage
 * @property {string|undefined} questionImage
 */

/**
 * @returns {Path}
 */
export default function useBaseURL() {
  const [
    {
      backendURL,
      profilePhotoBaseURL,
      selfiePhotoBaseURL,
      answerWithAudioBaseURL,
      answerWithImageBaseURL,
      questionImageBaseURL,
    },
  ] = useContext(AppContext);

  return {
    backend: backendURL,

    profilePhoto: profilePhotoBaseURL,
    selfiePhoto: selfiePhotoBaseURL,

    answerWithAudio: answerWithAudioBaseURL,
    answerWithImage: answerWithImageBaseURL,
    questionImage: questionImageBaseURL,
  };
}
