import ReactTooltip from 'react-tooltip';
import { useContext, useEffect, useState } from 'react';

import PhotoWithCardModal from './ShowPhotoWithCardModal';
import UploadPhotoWithCardModal from './UploadPhotoWithCardModal';

import Button from 'components/Button';
import PhotoContext from 'context/photo.context';
import {
  subscribeShowPhotoWithCardEvent,
  unsubscribeShowPhotoWithCardEvent,
} from 'utils/events';
import Typography from 'components/Typography';

function PhotoWithCardInformation() {
  const [photo] = useContext(PhotoContext);

  const [isPhotoDetailOpen, setIsPhotoDetailOpen] = useState(false);
  const [isUploadPhotoOpen, setIsUploadPhotoOpen] = useState(false);

  // Menampilkan modal lihat foto.
  const handlePhotoDetailShow = () => setIsPhotoDetailOpen(true);

  // Menutup modal lihat foto.
  const handlePhotoDetailClose = () => setIsPhotoDetailOpen(false);

  // Menampilkan modal ubah foto.
  const handleUploadPhotoShow = () => setIsUploadPhotoOpen(true);

  // Menutup modal ubuah foto.
  const handleUploadPhotoClose = () => setIsUploadPhotoOpen(false);

  // Listen event show photo with card.
  useEffect(() => {
    const handle = () => setIsUploadPhotoOpen(true);

    subscribeShowPhotoWithCardEvent(handle);
    return () => unsubscribeShowPhotoWithCardEvent(handle);
  });

  return (
    <>
      {/* Show photo modal */}
      <PhotoWithCardModal
        isOpen={isPhotoDetailOpen}
        onClose={handlePhotoDetailClose}
      />

      {/* Upload photo modal */}
      <UploadPhotoWithCardModal
        isOpen={isUploadPhotoOpen}
        onCancel={handleUploadPhotoClose}
        onUploaded={handleUploadPhotoClose}
      />

      {/* Photo with card information */}
      <div className="px-4 py-6 bg-green-100 border-l-4 border-green-500">
        <Typography className="font-normal">
          Rekam Foto Peserta &amp; Kartu Ujian
        </Typography>

        {/* Photo status */}
        <Typography variant="small" className="font-normal text-gray-500 mt-4">
          Foto Peserta &amp; Kartu Ujian{' '}
          {photo.selfie ? (
            <span className="text-green-500">telah tersimpan</span>
          ) : (
            <span className="text-red-700 font-semibold">belum diunggah</span>
          )}
        </Typography>

        {/* Action buttons */}
        <div className="grid grid-cols-2 gap-2 mt-4">
          {/* Show photo button */}
          <Button
            type="button"
            color="info"
            size="sm"
            data-tip="Lihat foto dengan kartu"
            onClick={handlePhotoDetailShow}
            block
          >
            Lihat Foto
          </Button>
          <ReactTooltip />

          {/* Recapture photo button */}
          <Button
            type="button"
            color="danger"
            size="sm"
            data-tip="Ganti foto saat ini (foto dengan kartu)"
            onClick={handleUploadPhotoShow}
            block
          >
            {photo.selfie ? 'Foto Ulang' : 'Unggah Foto'}
          </Button>
          <ReactTooltip />
        </div>
      </div>
    </>
  );
}

export default PhotoWithCardInformation;
