import { useContext, useState, useEffect, forwardRef } from 'react';
import ReactWebcam from 'react-webcam';
import propTypes from 'prop-types';

import { setCameraId } from 'actions/app.action';
import Select from 'components/Select';
import AppContext from 'context/app.context';

const WebCam = forwardRef(({ className, ...props }, ref) => {
  const [{ cameraId }, dispatchApp] = useContext(AppContext);

  const [devices, setDevices] = useState([]);

  const handleDevices = (mediaDevices) =>
    setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput'));

  const options = devices.map((device) => ({
    value: device.deviceId,
    text: device.label,
  }));

  const handleCameraChange = (_cameraId) => dispatchApp(setCameraId(_cameraId));

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, []);

  return (
    <div className={className}>
      <ReactWebcam
        ref={ref}
        audio={false}
        videoConstraints={{ deviceId: cameraId }}
        {...props}
      />

      {/* Device choices */}
      <div className="mt-5">
        <Select
          id="camera-choices"
          name="camera-choices"
          value={cameraId}
          onChange={(e) => handleCameraChange(e.target.value)}
          label="Pilih kamera"
          options={options}
        />
      </div>
    </div>
  );
});

WebCam.defaultProps = {
  className: '',
};

WebCam.propTypes = {
  className: propTypes.string,
};

export default WebCam;
