import { useCallback, useContext, useMemo, useState } from 'react';

import LoginForm from './LoginForm';
import LoginHelp from './LoginHelp';

import api from 'api';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Logo from 'components/Logo';
import UserContext from 'context/user.context';
import AppContext from 'context/app.context';
import { setAuthorizationToken } from 'utils/authorization';
import { setUser } from 'actions/user.action';
import { handleHttpError } from 'utils/httpError';
import { ReactComponent as FillFormImage } from 'assets/illustrations/fill-form.svg';
import { ReactComponent as LeafImage } from 'assets/illustrations/leaf.svg';
import Typography from 'components/Typography';
import { unsetBackendURL } from 'actions/app.action';
import CloseAppButton from 'components/Helpers/CloseAppButton';
import uinsa from 'assets/images/uinsa.png';
import HttpRequest from 'utils/httpRequest';

function LoginPage() {
  // const [permissions] = useContext(PermissionContext);
  const [, dispatchUser] = useContext(UserContext);
  const [, dispatchApp] = useContext(AppContext);

  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const areAllPermissionsAllowed = useMemo(
    () => true,
    []
    //   // () =>
    //   //   permissions.cameraAllowed &&
    //   //   permissions.microphoneAllowed &&
    //   //   permissions.notificationAllowed,
    //   // [
    //   //   permissions.cameraAllowed,
    //   //   permissions.microphoneAllowed,
    //   //   permissions.notificationAllowed,
    //   // ]
  );

  const handleSubmit = useCallback(
    async (values, { setSubmitting, setErrors }) => {
      try {
        const data = {
          ...values,
          time: Date.now(),
        };
        const dataPost = data;
        dataPost.userAgent = navigator.userAgent;
        const resultLogin = await new HttpRequest(api.auth.login())
          .setData(dataPost)
          .call();

        localStorage.setItem('lastLoginUsername', values.username);

        const { token } = resultLogin.data.data;
        setAuthorizationToken(token);

        const resultProfile = await new HttpRequest(
          api.profile.detail()
        ).call();
        const user = resultProfile.data.data;
        dispatchUser(setUser(user));

        // Auto redirect to /dashboard path
      } catch (err) {
        setSubmitting(false);
        localStorage.removeItem('token');

        if (err.response.status === 400) setErrors(err.response.data.errors);
        else handleHttpError(err);
      }
    },
    [dispatchUser]
  );

  const handleChangeDomainClick = useCallback(() => {
    dispatchApp(unsetBackendURL());
  }, [dispatchApp]);

  return (
    <>
      <div className="grid grid-cols-12 h-screen">
        <div
          className="md:col-span-6 col-span-12 relative"
          style={{ zIndex: 2 }}
        >
          <div className="container mx-auto flex flex-wrap content-center h-full w-full max-w-md">
            <div className="px-4 py-5">
              <Logo />

              <Typography variant="subtitle2" gutterBottom className="mt-8">
                Versi {process.env.REACT_APP_VERSION}
              </Typography>

              <Typography variant="heading2" gutterBottom className="mt-5">
                Login
              </Typography>

              {areAllPermissionsAllowed ? (
                <Typography color="muted" variant="subtitle3" gutterBottom>
                  Masuk terlebih dahulu untuk mulai ujian
                </Typography>
              ) : (
                <Alert color="danger" withBorder>
                  <Typography
                    as="div"
                    gutterBottom
                    className="2xl:mt-5 mt-2"
                    variant="paragraph"
                  >
                    <Typography className="font-medium" gutterBottom>
                      Aplikasi tidak dapat mengakses:
                    </Typography>
                    <ul>
                      {/* {!permissions.cameraAllowed && (
                        <li>
                          <strong>Kamera</strong>
                        </li>
                      )} */}
                      {/* {!permissions.microphoneAllowed && (
                        <li>
                          <strong>Mikrofon</strong>
                        </li>
                      )}
                      {!permissions.notificationAllowed && (
                        <li>
                          <strong>Notifikasi</strong>
                        </li>
                      )} */}
                    </ul>
                  </Typography>
                </Alert>
              )}

              <div className="mt-7">
                <LoginForm
                  onSubmit={handleSubmit}
                  // disabled={!areAllPermissionsAllowed}
                />

                <Button
                  type="button"
                  color="secondary"
                  className="mt-3"
                  onClick={handleChangeDomainClick}
                  block
                >
                  Ubah Domain
                </Button>
              </div>

              {/* <Typography color="muted" className="font-light text-center mt-5">
                Menemui suatu kendala? Klik{' '}
                <span
                  role="link"
                  className="link cursor-pointer"
                  onClick={() => setIsHelpModalOpen(true)}
                  onKeyDown={() => setIsHelpModalOpen(true)}
                  tabIndex={0}
                >
                  Bantuan
                </span>
              </Typography> */}

              <Typography variant="extra-small" className="text-center mt-10">
                Madrasah Education Quality Reform
              </Typography>

              <img
                src={uinsa}
                className="w-full h-auto mx-auto pt-5"
                style={{
                  maxWidth: '70px',
                }}
                alt="copyright"
              />
            </div>
          </div>

          <LeafImage
            className="absolute md:block hidden w-60 h-auto"
            style={{
              top: '100px',
              right: '-120px',
              background: 'transparent',
            }}
          />
        </div>

        <div
          className="md:col-span-6 md:block hidden relative bg-primary h-full overflow-hidden"
          style={{
            backgroundImage: 'linear-gradient(to right, #31D087, #00A65A)',
            zIndex: 1,
          }}
        >
          <div className="flex flex-wrap content-center h-full">
            <FillFormImage className="mx-auto lg:w-96 md:w-80" />
          </div>

          <div
            className="w-96 h-96 rounded-full absolute"
            style={{
              border: '70px solid rgba(110, 231, 183, .1)',
              top: '-240px',
              right: '-90px',
            }}
          />

          <div
            className="w-96 h-96 rounded-full absolute"
            style={{
              border: '70px solid rgba(110, 231, 183, .1)',
              bottom: '-240px',
              left: '-90px',
            }}
          />
        </div>
      </div>

      {/* Close app button */}
      <CloseAppButton />

      <LoginHelp
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
      />
    </>
  );
}

export default LoginPage;
