import { useContext, useRef } from 'react';
import propTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import WebCam from 'components/WebCam';
import { IMAGE_MIME_TYPE } from 'constant';
import ExamControllerContext from 'context/examController.context';

function GuestDashboard({ children }) {
  const history = useHistory();

  const [{ isSimulation, isStart }] = useContext(ExamControllerContext);

  const webcamRef = useRef(undefined);

  if (!isSimulation && isStart) {
    history.push('/login');
  }

  return (
    <>
      {children}

      <WebCam
        ref={webcamRef}
        audio={false}
        controls={false}
        screenshotFormat={IMAGE_MIME_TYPE}
        className="absolute -top-96 w-44"
      />

      <style jsx="true">{`
        body {
          background-color: #f7f9fd;
        }
      `}</style>
    </>
  );
}

GuestDashboard.propTypes = {
  children: propTypes.node.isRequired,
};

export default GuestDashboard;
