/**
 * @typedef {['get'|'post'|'put'|'delete',string]} HttpRequestEndpoint
 */

export default {
  /** @type {() => HttpRequestEndpoint} */
  getExamPackets: () => ['get', '/api/exam/packets'],

  /** @type {(examId: number) => HttpRequestEndpoint} */
  getExamPacketById: (examId) => ['get', `/api/exam/packets/${examId}`],

  /** @type {(examId: number) => HttpRequestEndpoint} */
  getExamPacketByStage: (examId) => [
    'get',
    `/api/exam/packets/${examId}/current-stage`,
  ],

  /** @type {(packetId: number) => HttpRequestEndpoint} */
  getQuestionsIdsByPk: (packetId) => ['get', `/api/exam/questions/${packetId}`],

  /** @type {(examId: number) => HttpRequestEndpoint} */
  start: (examId) => ['post', `/api/exam/${examId}/start`],

  /** @type {(examId: number) => HttpRequestEndpoint} */
  finish: (examId) => ['post', `/api/exam/${examId}/finish`],

  /** @type {(examId: number, questionId: number) => HttpRequestEndpoint} */
  getQuestion: (examId, questionId) => [
    'get',
    `/api/exam/${examId}/questions/${questionId}`,
  ],

  /** @type {(examId: number) => HttpRequestEndpoint} */
  getQuestions: (examId) => ['get', `/api/exam/${examId}/questions`],

  /** @type {(examId: number, stimulusId: number) => HttpRequestEndpoint} */
  getQuestionsByStimulus: (examId, stimulusId) => [
    'get',
    `/api/exam/${examId}/${stimulusId}/questions-by-stimulus`,
  ],

  /** @type {(examId: number) => HttpRequestEndpoint} */
  getAnswer: (examId) => ['get', `/api/exam/${examId}/answer`],

  /** @type {(examId: number) => HttpRequestEndpoint} */
  updateAnswer: (examId) => ['put', `/api/exam/${examId}/answer`],

  /** @type {(examId: number, answerIndex: number) => HttpRequestEndpoint} */
  updateAnswerAudio: (examId, answerIndex) => [
    'post',
    `/api/exam/${examId}/answer/${answerIndex}/audio`,
  ],

  /** @type {(examId: number, answerIndex: number) => HttpRequestEndpoint} */
  updateAnswerImage: (examId, answerIndex) => [
    'post',
    `/api/exam/${examId}/answer/${answerIndex}/image`,
  ],

  /** @type {(examId: number, answerIndex: number) => HttpRequestEndpoint} */
  updateAnswerPdf: (examId, answerIndex) => [
    'post',
    `/api/exam/${examId}/answer/${answerIndex}/pdf`,
  ],

  /** @type {() => HttpRequestEndpoint} */
  getSelfieWithCardPhoto: () => ['get', '/api/exam/photo/selfie-with-card'],

  /** @type {() => HttpRequestEndpoint} */
  postSelfieWithCardPhoto: () => ['post', '/api/exam/photo/selfie-with-card'],

  /** @type {(examId: number) => HttpRequestEndpoint} */
  postOnExamTimePhoto: (examId) => [
    'post',
    `/api/exam/${examId}/photo/on-exam-time`,
  ],

  /** @type {(examId: number) => HttpRequestEndpoint} */
  readWarning: (examId) => ['post', `/api/exam/${examId}/warning/read`],
};
