import { useRef, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { SECOND } from 'constant';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import popup from 'utils/popup';
import AppContext from 'context/app.context';
import HttpRequest from 'utils/httpRequest';
import api from 'api';
import { helpAnsweredEvent, warningEvent } from 'utils/events';

/**
 * @description
 * Call this hook only once in the project, because it's this hook has some
 * side effects.
 */
export default function useChecker() {
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();
  const [{ timeCheckSecurity, timeCheckNotif }] = useContext(AppContext);

  const timeIntervalRef = useRef();

  // Indikator apakah ada pesan alert yang tampil saat ini.
  const isAlertShowRef = useRef(false);

  // Interval waktu yang terus bertambah dan akan digunakan untuk melakukan
  // proses pengecekan.
  const [time, setTime] = useState(0);

  const forceQuit = () => {
    window?.ipcRenderer?.invoke('runForceQuit');
  };

  const runFocusTrue = () => {
    window?.ipcRenderer?.invoke('runFocusTrue');
  };

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      const { key, altKey } = e;
      if (key === 'F4' && altKey) {
        e.preventDefault();
      }
    });
  });

  // Menambahkan nilai time setiap setik.
  useEffect(() => {
    if (!timeIntervalRef.current) {
      timeIntervalRef.current = setInterval(
        () => setTime((currentTime) => currentTime + 1),
        1 * SECOND
      );
    }

    return () => clearInterval(timeIntervalRef.current);
  }, []);

  // Mengecek secuirty di awal saja
  useEffect(() => {
    window?.ipcRenderer?.invoke('runLogoutPaksa').then((val) => {
      const mustForcedOut =
        typeof val === 'object' && Object.keys(val).length > 0;

      if (!mustForcedOut) return;

      popup
        .showError(val?.title, val?.subtitle, {
          closeOnClickOutside: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        })
        .then(() => forceQuit());
    });
  }, []);

  // Mengecek status blur setiap waktu yang sudah ditentukan.
  useEffect(() => {
    const checkBlurInterval = setInterval(() => {
      if (isAlertShowRef.current === false) {
        window?.ipcRenderer?.send('check-blur-status');
      }
    }, 0.5 * SECOND);

    return () => clearInterval(checkBlurInterval);
  }, []);

  // Mengecek focusable.
  useEffect(() => {
    function handle(event, val) {
      if (isAlertShowRef.current) return;

      isAlertShowRef.current = true;
      popup
        .showError(val?.title, val?.subtitle, {
          allowOutsideClick: false,
          allowEscapeKey: false,
        })
        .then(() => {
          runFocusTrue();
          isAlertShowRef.current = false;
        });
    }

    window?.ipcRenderer?.on('runPeringatanFocus', handle);

    return () =>
      window?.ipcRenderer?.removeListener('runPeringatanFocus', handle);
  }, []);

  // Mengecek display added.
  useEffect(() => {
    function handle(event, val) {
      if (isAlertShowRef.current) return;

      isAlertShowRef.current = true;
      popup
        .showError(val?.title, val?.subtitle, {
          allowOutsideClick: false,
          allowEscapeKey: false,
        })
        .then(() => {
          runFocusTrue();
          isAlertShowRef.current = false;
        });
    }

    window?.ipcRenderer?.on('runPeringatanDisplayAdded', handle);

    return () =>
      window?.ipcRenderer?.removeListener('runPeringatanDisplayAdded', handle);
  }, []);

  // Mengecek apakah peserta menjalankan program lain atau tidak.
  useEffect(() => {
    if (time % timeCheckSecurity === 0) {
      window?.ipcRenderer?.invoke('runLogoutPaksa').then((val) => {
        const mustForcedOut =
          typeof val === 'object' && Object.keys(val).length > 0;

        if (!mustForcedOut) return;
        if (isAuthenticated) history.push(`/logout`);

        popup
          .showError(val?.title, val?.subtitle, {
            closeOnClickOutside: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          })
          .then(() => forceQuit());
      });
    }
  }, [history, isAuthenticated, time, timeCheckSecurity]);

  // Mengecek status bantuan dan teguran secara manual.
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await new HttpRequest(
          api.status.get(localStorage.getItem('lastLoginUsername') || null)
        ).call();

        const { warnings, staTanya, katTanya, textTanya, textJawab } =
          res.data.data;

        // Jika pertanyaan sudah dijawab oleh pengawas atau pusat.
        if (staTanya === 2 || staTanya === 4) {
          const data = { staTanya, katTanya, textTanya, textJawab };

          // Variable untuk membantu mencegah popup yang sama muncul berkali-kali
          const lastHelpString = localStorage.getItem('lastHelpString') || '';

          // Jika popup yang sama pernah muncul, mana jangan munculkan lagi.
          if (JSON.stringify(data) === lastHelpString) return;

          localStorage.setItem('lastHelpString', JSON.stringify(data));
          helpAnsweredEvent.value = data;
          window.dispatchEvent(helpAnsweredEvent);
        }

        // Jika ada teguran.
        if (warnings.length > 0) {
          warningEvent.value = warnings;
          window.dispatchEvent(warningEvent);
        }
      } catch (err) {
        console.warn(err);
      }
    }

    try {
      if (
        timeCheckNotif &&
        typeof timeCheckNotif === 'number' &&
        timeCheckNotif > 0 &&
        time % timeCheckNotif === 0
      ) {
        fetchData();
      }
    } catch {
      /** */
    }
  }, [time, timeCheckNotif]);
}
