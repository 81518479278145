import {
  EXAM_IS_OVER,
  HAS_WARNING,
  HELP_ANSWERED,
  RECONNECTED,
  SHOW_UPLOAD_PHOTO_WITH_CARD,
  STAGE_CHANGED,
  TOKEN_REFRESH_SUCCESS,
} from 'constant';

export const showPhotoWithCardEvent = new Event(SHOW_UPLOAD_PHOTO_WITH_CARD);

export function subscribeShowPhotoWithCardEvent(cb) {
  window.addEventListener(SHOW_UPLOAD_PHOTO_WITH_CARD, cb);
}

export function unsubscribeShowPhotoWithCardEvent(cb) {
  window.removeEventListener(SHOW_UPLOAD_PHOTO_WITH_CARD, cb);
}

export const reconnectedEvent = new Event(RECONNECTED);

export function subscribeReconnectedEvent(cb) {
  window.addEventListener(RECONNECTED, cb);
}

export function unsubscribeReconnectedEvent(cb) {
  window.removeEventListener(RECONNECTED, cb);
}

export const examIsOverEvent = new Event(EXAM_IS_OVER);

export function subscribeExamIsOverEvent(cb) {
  window.addEventListener(EXAM_IS_OVER, cb);
}

export function unsubscribeExamIsOverEvent(cb) {
  window.removeEventListener(EXAM_IS_OVER, cb);
}

// Event yang akan ditrigger ketika ada teguran dari pengawas. Event ini akan
// dipanggil melalui pengecekan status teguran secara manual pada file
// useChecker.js
export const warningEvent = new Event(HAS_WARNING);

export function subscribeWarningEvent(cb) {
  window.addEventListener(HAS_WARNING, cb);
}

export function unsubscribeWarningEvent(cb) {
  window.removeEventListener(HAS_WARNING, cb);
}

// Event yang akan ditrigger ketika pertanyaan yang sebelumnya diajukan sudah
// dijawab oleh pengawas atau pusat. Event ini akan dipanggil melalui pengecekan
// status bantuan secara manual pada file useChecker.js
export const helpAnsweredEvent = new Event(HELP_ANSWERED);

export function subscribeHelpAnsweredEvent(cb) {
  window.addEventListener(HELP_ANSWERED, cb);
}

export function unsubscribeHelpAnsweredEvent(cb) {
  window.removeEventListener(HELP_ANSWERED, cb);
}

// Event yang akan ditrigger ketika terdapat suatu request yang gagal karena
// unauthorized, kemudian peserta mengklik tombol coba lagi untuk merefresh
// token baru. Dan ketika refresh token baru berhasil, maka event ini akan
// tertrigger untuk merequest ulang request-request yang gagal sebelumnya.
export const tokenRefreshSuccessEvent = new Event(TOKEN_REFRESH_SUCCESS);

export function subscribeTokenRefreshSuccessEvent(cb) {
  window.addEventListener(TOKEN_REFRESH_SUCCESS, cb);
}

export function unsubscribeTokenRefreshSuccessEvent(cb) {
  window.removeEventListener(TOKEN_REFRESH_SUCCESS, cb);
}

// @todo docs
export const stageChangedEvent = new Event(STAGE_CHANGED);

export function subscribeStageChangedEvent(cb) {
  window.addEventListener(STAGE_CHANGED, cb);
}

export function unsubscribeStageChangedEvent(cb) {
  window.removeEventListener(STAGE_CHANGED, cb);
}
