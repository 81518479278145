/* eslint-disable jsx-a11y/label-has-associated-control */
import propTypes from 'prop-types';
import { MathJax } from 'better-react-mathjax';

import clsx from 'libs/clsx';

function Label({ htmlFor, error, children }) {
  return (
    <MathJax>
      <label
        className={clsx(
          'block text-gray-400 xl:text-base text-sm font-light mb-2',
          error && 'text-red-500'
        )}
        htmlFor={htmlFor}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: children }}
      />
    </MathJax>
  );
}

Label.defaultProps = {
  error: null,
};

Label.propTypes = {
  htmlFor: propTypes.string.isRequired,
  error: propTypes.string,
  children: propTypes.node.isRequired,
};

export default Label;
