/* eslint-disable react/prop-types */
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';

import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import Button from 'components/Button';
import Input from 'components/Input';

function LoginForm({ onSubmit, disabled: formDisabled }) {
  return (
    <Formik initialValues={{ username: '', password: '' }} onSubmit={onSubmit}>
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <MaskedInput
              mask={[
                /[0-9]/,
                /[0-9]/,
                '.',
                /[0-9]/,
                /[0-9]/,
                '.',
                /[0-9]/,
                /[0-9]/,
                '.',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '.',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
              ]}
              type="text"
              name="username"
              id="username"
              placeholder="Kode pendaftaran"
              value={values.username}
              onChange={handleChange}
              disabled={formDisabled || isSubmitting}
              autoFocus
              render={(ref, props) => (
                <Input
                  ref={(input) => ref(input)}
                  label="Username:"
                  icon={<PersonIcon className="w-6 text-gray-500" />}
                  error={errors.username}
                  {...props}
                />
              )}
            />
          </div>

          <div className="mb-10">
            <MaskedInput
              mask={[
                /[a-zA-Z0-9]/,
                /[a-zA-Z0-9]/,
                /[a-zA-Z0-9]/,
                /[a-zA-Z0-9]/,
                /[a-zA-Z0-9]/,
                /[a-zA-Z0-9]/,
              ]}
              type="text"
              name="password"
              id="password"
              placeholder="Kata sandi"
              value={values.password}
              onChange={handleChange}
              disabled={formDisabled || isSubmitting}
              render={(ref, props) => (
                <Input
                  ref={(input) => ref(input)}
                  label="Password:"
                  icon={<LockIcon className="w-6 text-gray-500" />}
                  error={errors.password}
                  {...props}
                />
              )}
            />
          </div>

          <Button
            type="submit"
            color="primary"
            loading={isSubmitting}
            disabled={formDisabled || isSubmitting}
            block
          >
            Login
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default LoginForm;
