/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import {
  forwardRef,
  memo,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { MathJax } from 'better-react-mathjax';
import { v4 as uuidv4 } from 'uuid';

import Container from './Container';

import Typography from 'components/Typography';
import useBaseURL from 'hooks/useBaseURL';
import { shuffleChoices } from 'utils/exam';

// Pattern untuk kolom jawaban.
const DRAG_PATTERN = /<@drag>/gi;

function ExamPopup(
  { question: { id, question, additional }, answer, onAnswerChange },
  ref // eslint-disable-line no-unused-vars
) {
  const path = useBaseURL();

  // Jawaban peserta berupa array, panjang dari array sesuai dengan string
  // <@drag> yang ada di variable `question`.
  //
  // Jika jawaban kosong, maka isinya [null, null, null, ...n].
  //
  // Jika kolom jawaban 1 dan 3 terjawab, maka isinya [0, null, 1, ...n], 0 dan
  // 1 merupakan index dari jawaban tcck yang ada di variable additional.
  const [answers, setAnswers] = useState([]);
  // const [mathElementsTks, setMathElementsTks] = useState([]);

  // Mengambil string pilihan jawabannya.
  const choices = useMemo(
    () =>
      additional.map((item) =>
        item.dsk.replace(/@url\//g, `${path.questionImage}c/`)
      ),
    [additional, path.questionImage]
  );

  const [shuffledChoices, shuffledIndexes] = useMemo(
    () => shuffleChoices(choices),
    [choices]
  );

  // Pilihan jawaban yang tersedia dan belum dipilih oleh peserta untuk menjawab
  // kolom-kolom jawaban. Isi dari variable ini adalah array of string,
  // stringnya adalah string pilihan jawabannya.
  const availableChoices = useMemo(
    () =>
      shuffledChoices.map((choice, index) => {
        const realIndex = shuffledIndexes[index];

        // Mengubah pilihan jawaban menjadi `null` ketika pilihan jawaban ini
        // sudah digunakan pada kolom jawaban.
        return answers.includes(realIndex) ? null : choice;
      }),
    [answers, shuffledChoices, shuffledIndexes]
  );

  // Jumlah dari kolom jawaban yang tersedia.
  const columnTotal = useMemo(
    () => (question.match(DRAG_PATTERN) || []).length,
    [question]
  );

  const [popoversOpen, setPopoversOpen] = useState(
    Array.from({ length: columnTotal }, () => false)
  );

  const handlePopoverOpen = (index) =>
    setPopoversOpen((popovers) =>
      popovers.map((open, i) => (i === index ? true : open))
    );

  const handlePopoverClose = (index) =>
    setPopoversOpen((popovers) =>
      popovers.map((open, i) => (i === index ? false : open))
    );

  // String HTML yang digunakan untuk menampilkan soal dan jawaban.
  const html = useMemo(
    () => {
      let index = 0;

      // const regexMathML = /<math(.*?<\/math>)/g;

      // Menambahkan string mathML ke dalam state.
      // setMathElementsTks(question.match(regexMathML) || []);

      // Replace [@url] dengan questionImage path.
      const tempQuestionString = question.replace(
        /@url\//g,
        `${path.questionImage}p/`
      );

      // Mereplace mathML menjadi span.
      // const returnTksMathML = tempQuestionString.replace(
      //   regexMathML,
      //   // eslint-disable-next-line no-plusplus
      //   () => `<span mathjax="true" index="${index++}"></span>`
      // );

      index = 0;
      // Mengubah string <@drag> menjadi span dan diberi identitas
      // exam-popup-container="true" dan index. Tujuan pemberian identitas
      // adalah untuk mengetahui index dari setiap kolom jawaban.
      // Tujuan menggunakan span di sini adalah untuk pengelolaan lebih lanjut.
      return tempQuestionString.replace(
        DRAG_PATTERN,
        () => `<span exam-popup-container="true" index="${index++}"></span>`
      );
    },
    // Hanya render ketika ada perubahan id dan pertanyaan.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, path.questionImage, question]
  );

  // Manangani ketika peserta mengubah jawaban.
  const handleAnswerChange = useCallback(
    (dragIndex, choiceIndex) => {
      const realChoiceIndex = shuffledIndexes[choiceIndex];

      // Array dari jawaban baru.
      const newAnswer = answers.map((currentAnswer, index) =>
        index === dragIndex ? realChoiceIndex : currentAnswer
      );

      setAnswers(newAnswer);

      // Jawaban baru berupa string.
      const newAnswerString = newAnswer
        .map((_answer) => (_answer === null ? ' ' : _answer))
        .join('|');

      onAnswerChange(
        // Jika semua jawaban belum diisi, maka ubah jadi ' '.
        newAnswerString.match(/^\s+$/g) ? ' ' : newAnswerString
      );

      handlePopoverClose(dragIndex);
    },
    [answers, onAnswerChange, shuffledIndexes]
  );

  // Menangani ketika jawaban direset melalui tombol "Batalkan Jawaban".
  useEffect(() => {
    if (answer === ' ') {
      setAnswers(Array.from(Array(columnTotal)).fill(null));
    }
  }, [answer, columnTotal]);

  // Menangani pengelolaan jawaban saat komponen baru pertama kali dirender.
  useEffect(() => {
    if (answer === ' ') {
      setAnswers(Array.from(Array(columnTotal)).fill(null));
    } else {
      // Memecah jawaban menjadi array.
      const currentAnswers = answer.split('|');

      // Jika panjang dari jawaban dan jumlah kolom tidak sesuai, maka reset
      // jawabannya.
      if (currentAnswers.length !== columnTotal) {
        setAnswers(Array.from(Array(columnTotal)).fill(null));
      } else {
        // Jika panjang jawaban dan jumlah kolom sama.
        setAnswers(
          // Ubah tipe data jawaban menjadi number dan ubah strning kosong ' '
          // menjadi null.
          currentAnswers.map((currentAnswer) => {
            const num = parseInt(currentAnswer, 10);
            return num >= 0 ? num : null;
          })
        );
      }
    }
    // Hanya eksekusi ketika terdapat perubahan jumlah kolom dan id soal.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnTotal, id]);

  return (
    <Typography as="div" className="font-readable">
      <MathJax>
        {/* Merender HTML */}
        {ReactHtmlParser(html, {
          // Melakukan transformasi terhadap elemen
          // <span exam-popup-container="true"> menjadi react component agar
          // dapat dikelola event-eventnya (onClick, dsb).
          // eslint-disable-next-line consistent-return
          transform: (node) => {
            if (node.attribs?.['exam-popup-container']) {
              // Index dari kolom
              const columnIndex = parseInt(node.attribs.index, 10);

              // Jawaban yang dipilih pada kolom jawaban index ini.
              const additionalIndex = answers[columnIndex];

              return (
                <Container
                  key={uuidv4()}
                  isOpen={popoversOpen[columnIndex]}
                  onOpen={() => handlePopoverOpen(columnIndex)}
                  onClose={() => handlePopoverClose(columnIndex)}
                  answer={
                    // Jika kolom ini terjawab, maka tampilkan jawabannya.
                    typeof additionalIndex === 'number'
                      ? choices[additionalIndex]
                      : null
                  }
                  choices={availableChoices}
                  onAnswerChange={(choiceIndex) =>
                    handleAnswerChange(columnIndex, choiceIndex)
                  }
                />
              );
            }
            // if (node.name === 'span' && node.attribs?.mathjax === 'true') {
            //   const index = parseInt(node.attribs.index, 10);
            //   const mathElement = mathElementsTks[index];

            //   if (typeof mathElement === 'string') {
            //     return (
            //       <MathJax
            //         key={uuidv4()}
            //         math={String.raw`${mathElement}`}
            //         style={{ display: 'inline-block' }}
            //       />
            //     );
            //   }
            // }
          },
        })}
      </MathJax>
    </Typography>
  );
}

ExamPopup = forwardRef(ExamPopup);

export default memo(ExamPopup);
