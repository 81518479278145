import axios from 'axios';

import auth from './auth';
import configApi from './config';
import exam from './exam';
import help from './help';
import profile from './profile';
import status from './status';

axios.defaults.baseURL = localStorage.getItem('backendURL');
axios.defaults.timeout = 10000;

const api = {
  auth,
  config: configApi,
  profile,
  exam,
  help,
  status,
};

export default api;
