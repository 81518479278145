import { secondsToText, secondsToTimerText } from 'libs/time';
import * as examHelper from 'utils/exam';

/**
@todo Mengubah model menjadi seperti ini.
examId
examCode
examName
examDescription
examDate
examDuration
examStatus

roomCode
roomName
roomDescription

packetName
packetDescription

warningStatus
warningMessage

examProgressAnswer
examProgressAnswerTime
examProgressReadQuestionTime

examProgressStatus
examProgressRemainingTime
 */
export default function ExamModel(exam) {
  // const currentStage = STAGE_FIELDS.jwb[exam.paket_ujian.sta];
  // const stage = examHelper.getNearestStage(exam);

  this.id = exam.paket_ujian.idUj;
  this.idPk = exam.paket_ujian.idPk;
  this.idPk2 = exam.paket_ujian.idPk2;
  this.idPk3 = exam.paket_ujian.idPk3;

  this.name = exam.ujian.nmu;
  // this.questionTotal = exam[STAGE_FIELDS.paket_soal[stage]].idSl.length;

  this.duration = exam.ujian.drs;
  this.durationText = secondsToText(this.duration);
  this.durationTimerText = secondsToTimerText(this.duration);

  this.remainingTime = examHelper.getRemainingTime(exam);
  this.remainingTimeText = secondsToText(this.remainingTime);
  this.remainingTimeTimerText = secondsToTimerText(this.remainingTime);

  // this.percentage = examHelper.getPercentage(exam);
  this.status = examHelper.getStatus(exam);
}
