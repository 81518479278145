import propTypes from 'prop-types';
import { forwardRef } from 'react';

import clsx from 'libs/clsx';

const Card = forwardRef(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx('rounded-xl bg-white px-6 py-6', className)}
    {...props}
  >
    {children}
  </div>
));

Card.defaultProps = {
  className: '',
};

Card.propTypes = {
  className: propTypes.string,
  children: propTypes.node.isRequired,
};

export default Card;
