import {
  SET_CAMERA_PERMISSION,
  SET_MICROPHONE_PERMISSION,
  SET_NOTIFICATION_PERMISSION,
} from 'constant';

export const setCameraPermission = (status) => ({
  type: SET_CAMERA_PERMISSION,
  payload: status,
});

export const setMicrophonePermission = (status) => ({
  type: SET_MICROPHONE_PERMISSION,
  payload: status,
});

export const setNotificationPermission = (status) => ({
  type: SET_NOTIFICATION_PERMISSION,
  payload: status,
});
