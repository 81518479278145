import { useEffect, useRef, useState, useCallback } from 'react';

import { SECOND } from 'constant';

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}

const useRecorder = () => {
  const [audio, setAudio] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [seconds, setSeconds] = useState(0);

  const intervalSeconds = useRef(undefined);

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      intervalSeconds.current = setInterval(() => {
        setSeconds((currentValue) => currentValue + 1);
      }, 1 * SECOND);
      try {
        recorder.start();
      } catch (err) {
        /** */
      }
    } else {
      clearInterval(intervalSeconds.current);
      setSeconds(0);
      try {
        recorder.stop();
      } catch (err) {
        /** */
      }
    }

    // Obtain the audio when ready.
    const handleData = (e) => {
      setAudio(e.data);
    };
    recorder.addEventListener('dataavailable', handleData);
    // eslint-disable-next-line consistent-return
    return () => {
      recorder.removeEventListener('dataavailable', handleData);
      clearInterval(intervalSeconds.current);
    };
  }, [recorder, isRecording]);

  const startRecording = useCallback(() => {
    setIsRecording(true);
  }, []);

  const stopRecording = useCallback(() => {
    setIsRecording(false);
  }, []);

  const deleteRecorded = useCallback(() => {
    setAudio(null);
  }, []);

  const resetAudioTimeoutRef = useRef();

  const forceRecording = useCallback(() => {
    setIsRecording(false);
    setAudio(null);

    // Mengubah kembali setAudio menjadi null. Karena ketika ada perubahan
    // status isRecording yang sebelumnya true menjadi false, maka variable
    // audio akan memiliki isi.
    resetAudioTimeoutRef.current = setTimeout(() => setAudio(null), 100);
  }, []);

  useEffect(() => () => clearTimeout(resetAudioTimeoutRef.current), []);

  return {
    audio,
    isRecording,
    startRecording,
    stopRecording,
    deleteRecorded,
    forceRecording,
    seconds,
  };
};

export default useRecorder;
