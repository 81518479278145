/* eslint-disable react/prop-types */
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  useImperativeHandle,
} from 'react';
import ReactTooltip from 'react-tooltip';

import useRecorder from './useRecorder';

import { ReactComponent as MicIcon } from 'assets/icons/mic.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as StopIcon } from 'assets/icons/stop.svg';
import { secondsToTimerText } from 'libs/time';
import { safeJoinURL } from 'libs/url';
import useBaseURL from 'hooks/useBaseURL';

function ExamVoice({ answer, onAnswerChange }, ref) {
  const {
    audio,
    isRecording,
    startRecording,
    stopRecording,
    deleteRecorded,
    forceRecording,
    seconds,
  } = useRecorder();
  const baseURL = useBaseURL();

  // Apakah peserta sudah merekam sebelumnya.
  const hasAnswer = useMemo(() => answer !== ' ', [answer]);

  // URL dari audio yang baru saja direkam.
  const audioURL = useMemo(
    () => (audio ? URL.createObjectURL(audio) : null),
    [audio]
  );

  // Data spectrum.
  const [spectrumData, setSpectrumData] = useState([]);

  // Spectrum
  const spectrumRef = useRef(null);
  const spectrumAnalyserRef = useRef();
  const spectrumAudioSrcRef = useRef();
  const spectrumAnimationTimeoutRef = useRef();

  // Menangani tombol simpan rekaman.
  const handleSaveButtonClick = useCallback(() => {
    if (!audio) return;
    onAnswerChange(audio);
  }, [audio, onAnswerChange]);

  // Menggambar spectrum di dalam canvas.
  const drawSpectrumAnimation = useCallback((data) => {
    if (!spectrumRef.current) return;

    const canvas = spectrumRef.current;
    const canvasCtx = canvas.getContext('2d');

    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
    canvasCtx.fillStyle = '#ACFFAD';

    const bars = 50;
    const barWidth = 4;

    for (let i = 0; i < bars; i += 1) {
      const barX = i * 10;
      const barHeight = (data[i] || 0) / 3;

      canvasCtx.fillRect(barX, canvas.height / 2, barWidth, barHeight);
      canvasCtx.fillRect(barX, canvas.height / 2, barWidth, -barHeight);
    }
  }, []);

  const animateSpectrum = useCallback(() => {
    if (!spectrumRef.current || !spectrumAnalyserRef.current) return;

    spectrumAnimationTimeoutRef.current = setTimeout(animateSpectrum, 50);
    spectrumAnalyserRef.current.getByteFrequencyData(spectrumData);
    drawSpectrumAnimation(spectrumData);
  }, [spectrumData, drawSpectrumAnimation]);

  // Konfigurasi analyser dan memulai spectrum.
  const runSpectrumAnimation = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
      });

      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      const analyser = audioCtx.createAnalyser();
      analyser.fftSize = 2048;

      const audioSrc = audioCtx.createMediaStreamSource(stream);
      audioSrc.connect(analyser);

      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      spectrumAnalyserRef.current = analyser;
      spectrumAudioSrcRef.current = audioSrc;

      setSpectrumData(dataArray);
    } catch (err) {
      console.warn(err);
    }
  }, []);

  const stopSpectrumAnimation = useCallback(() => {
    spectrumAnalyserRef?.current?.disconnect();
    spectrumAudioSrcRef?.current?.disconnect();

    clearTimeout(spectrumAnimationTimeoutRef.current);
  }, []);

  useEffect(() => {
    spectrumAnimationTimeoutRef.current = setTimeout(animateSpectrum, 50);
    return () => clearTimeout(spectrumAnimationTimeoutRef.current);
  }, [animateSpectrum]);

  // Otomatis mereset rekaman ketika ada perubahan props answer.
  useEffect(() => {
    if (answer !== ' ') deleteRecorded();
  }, [answer, deleteRecorded]);

  // Otomatis memulai dan menghentikan spectrum.
  useEffect(() => {
    if (isRecording) runSpectrumAnimation();
    else stopSpectrumAnimation();
  }, [runSpectrumAnimation, isRecording, stopSpectrumAnimation]);

  // Mereset audio yang sudah terekam saat terjadi perubahan jawaban.
  useEffect(() => {
    if (answer === ' ') forceRecording();
  }, [answer, forceRecording]);

  useImperativeHandle(
    ref,
    () => ({
      componentWillUnmount: () => {
        if (isRecording) forceRecording();
        if (audio) deleteRecorded();
      },
    }),
    [audio, deleteRecorded, forceRecording, isRecording]
  );

  return (
    <>
      {/* Voice spectrum */}
      {isRecording && (
        <div className="overflow-x-auto">
          <canvas ref={spectrumRef} width={400} className="mx-auto" />
        </div>
      )}

      {/* Audio player */}
      <div>
        {!isRecording && hasAnswer && !audio && (
          /* eslint-disable-next-line jsx-a11y/media-has-caption */
          <audio
            src={safeJoinURL(baseURL.answerWithAudio, answer)}
            controls
            preload="auto"
            controlsList="nodownload"
            className="mx-auto"
          />
        )}
        {!isRecording && hasAnswer && audio && (
          /* eslint-disable-next-line jsx-a11y/media-has-caption */
          <audio
            src={audioURL}
            controls
            preload="auto"
            controlsList="nodownload"
            className="mx-auto"
          />
        )}
        {!isRecording && !hasAnswer && audio && (
          /* eslint-disable-next-line jsx-a11y/media-has-caption */
          <audio
            src={audioURL}
            controls
            preload="auto"
            controlsList="nodownload"
            className="mx-auto"
          />
        )}
      </div>

      <div className="mt-5">
        {!isRecording && (
          <div className="flex justify-center gap-3">
            {/* Delete recording button */}
            {audio && (
              <div>
                <button
                  type="button"
                  className="p-2 shadow bg-transparent hover:bg-red-100 border-2 border-red-500 rounded-full"
                  onClick={deleteRecorded}
                  data-tip="Hapus rekaman ini"
                >
                  <CloseIcon className="w-7 h-7 text-red-500" />
                </button>
                <ReactTooltip />
              </div>
            )}

            {/* Record button */}
            <div>
              <button
                type="button"
                className="p-2 shadow bg-primary hover:bg-green-600 border-2 border-green-500 rounded-full"
                onClick={startRecording}
                data-tip="Rekam suara"
              >
                <MicIcon className="w-7 h-7 text-white" />
              </button>
              <ReactTooltip />
            </div>

            {/* Save button */}
            {audio && (
              <div>
                <button
                  type="button"
                  className="p-2 shadow bg-transparent hover:bg-green-100 border-2 border-green-500 rounded-full"
                  onClick={handleSaveButtonClick}
                  data-tip="Simpan hasil rekaman ini"
                >
                  <CheckIcon className="w-7 h-7 text-green-500" />
                </button>
                <ReactTooltip />
              </div>
            )}
          </div>
        )}

        {isRecording && (
          <div className="text-center">
            {/* Stop recording button */}
            <div className="block">
              <button
                type="button"
                className="p-4 shadow bg-danger hover:bg-red-700 rounded-full"
                onClick={stopRecording}
                data-tip="Stop rekaman"
              >
                <StopIcon className="w-4 h-4 text-white" />
              </button>
              <ReactTooltip />
            </div>

            {/* Recording timer */}
            <div>
              {secondsToTimerText(seconds).split(':').slice(1, 3).join(':')}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

ExamVoice = forwardRef(ExamVoice);

export default memo(ExamVoice);
