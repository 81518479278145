/**
 * Fungsi yang digunakan untuk menggabungkan beberapa `className` yang bisa
 * dikombinasikan dengan if statement.
 *
 * @param  {...String} args berisi nama-nama class pada elemen
 * @returns {String}
 *
 * @example clsx('bg-red-500', loading && 'bg-blue-500')
 */
function clsx(...args) {
  const validString = args.filter((arg) => typeof arg === 'string');
  return validString.join(' ');
}

export default clsx;
