/* eslint-disable react/prop-types */
import shallow from 'zustand/shallow';
import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import useCanRefresh from './useCanRefresh';

import Button from 'components/Button';
import Typography from 'components/Typography';

const QuestionHeader = ({
  isSimulation,
  currentQuestionIndex,
  questionTotal,
  onRefresh,
}) => {
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);

  // @todo optimasi component yang terender. Terlalu banyak merender.
  const refresh = useCanRefresh((state) => state, shallow);
  const refreshStatus = useCanRefresh(
    (state) => ({
      status: state.list[currentQuestionIndex]?.status,
      willEnabledOn: state.list[currentQuestionIndex]?.willEnabledOn,
      enableBackByTimeout: () =>
        state.enableBackByTimeout(currentQuestionIndex),
    }),
    shallow
  );

  useEffect(() => {
    if (refresh.list.length === 0 || refresh.list.length !== questionTotal) {
      refresh.initList(questionTotal);
    }
  }, [questionTotal, refresh, refresh.list.length]);

  // Refresh soal.
  const handleRefreshClick = () => {
    if (!refreshStatus.status) return;

    setIsRefreshLoading(true);
    onRefresh({ done: () => setIsRefreshLoading(false) });

    refreshStatus.enableBackByTimeout();
  };

  return (
    <div className="flex justify-between items-center">
      <Typography variant="subtitle1" className="font-medium" gutterBottom>
        <span className="text-gray-400">Soal</span> {currentQuestionIndex + 1}{' '}
        <span className="text-gray-400">dari</span> {questionTotal}{' '}
        {isSimulation && (
          <span className="text-gray-400">- #Soal Simulasi</span>
        )}
      </Typography>

      <div>
        <Button
          type="button"
          onClick={handleRefreshClick}
          size="sm"
          color="secondary"
          data-tip={
            refreshStatus.status
              ? 'Refresh data soal'
              : `Anda dapat mencoba lagi dalam ${refreshStatus.willEnabledOn} detik`
          }
          loading={isRefreshLoading}
          disabled={isRefreshLoading}
        >
          Refresh&nbsp;
          {!refreshStatus.status && <>({refreshStatus.willEnabledOn})</>}
        </Button>

        <ReactTooltip />
      </div>
    </div>
  );
};

export default QuestionHeader;
