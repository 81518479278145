/* eslint-disable no-param-reassign */
import create from 'zustand';
import produce from 'immer';

import configuration from 'configuration';
import { SECOND } from 'constant';

const useCanRefresh = create((set) => ({
  list: [],

  // Mengisi nilai untuk state masing-masing soal.
  initList: (length) => {
    set({
      list: Array(length).fill({
        // Jika true maka diperboleh me-refresh.
        status: true,
        // Sisa waktu agar tombol refresh dapat diklik.
        willEnabledOn: 0,
        // interval untuk mengurangi nilai dari willEnableOn.
        interval: undefined,
        // Timeout membuat status can refresh menjadi true.
        timeout: undefined,
      }),
    });
  },

  // Mengizinkan refresh kembali setelah waktu yang sudah ditentukan.
  enableBackByTimeout: (index) =>
    set((state) => {
      clearInterval(state.list[index].interval);
      clearTimeout(state.list[index].timeout);

      // eslint-disable-next-line no-shadow
      return produce((state) => {
        state.list[index].status = false;
        state.list[index].willEnabledOn =
          configuration.REFRESH_QUESTION_BREAK_TIME;

        // Mengatur pengurangan willEnabledOn secara live sampai menyentuh angka
        // nol.
        state.list[index].interval = setInterval(
          () =>
            set(
              produce((_state) => {
                if (_state.list[index].willEnabledOn === 0) {
                  clearInterval(_state.list[index].interval);
                } else {
                  _state.list[index].willEnabledOn -= 1;
                }
              })
            ),
          1 * SECOND
        );

        // Mengubah status menjadi true ketika selisih waktu refresh sudah
        // terlewat.
        state.list[index].timeout = setTimeout(
          () =>
            set(
              produce((_state) => {
                _state.list[index].status = true;
              })
            ),
          configuration.REFRESH_QUESTION_BREAK_TIME * SECOND
        );
      })(state);
    }),
}));

export default useCanRefresh;
