import imageCompression from 'browser-image-compression';

import { IMAGE_EXTENSION, IMAGE_MIME_TYPE } from 'constant';

// eslint-disable-next-line import/prefer-default-export
export async function sanitizeSelfieImage(imageBase64) {
  const imageBlob = await fetch(imageBase64).then((res) => res.blob());
  const imageFile = new File(
    [imageBlob],
    `selfie-with-card.${IMAGE_EXTENSION}`,
    {
      type: IMAGE_MIME_TYPE,
    }
  );

  const compressedFile = await imageCompression(imageFile, { maxSizeMB: 1 });

  const newImageBlob = await fetch(URL.createObjectURL(compressedFile)).then(
    (res) => res.blob()
  );

  return new File([newImageBlob], `selfie-with-card.${IMAGE_EXTENSION}`, {
    type: IMAGE_MIME_TYPE,
  });
}
