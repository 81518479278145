import { SET_SELFIE_PHOTO, UNSET_PHOTOS, UNSET_SELFIE_PHOTO } from 'constant';

export const setSelfiePhoto = (photo) => ({
  type: SET_SELFIE_PHOTO,
  payload: photo,
});

export const unsetSelfiePhoto = () => ({ type: UNSET_SELFIE_PHOTO });

export const unsetPhotos = () => ({ type: UNSET_PHOTOS });
