import { useCallback, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import api from 'api';
import { unsetExams } from 'actions/exam.action';
import { unsetUser } from 'actions/user.action';
import ExamContext from 'context/exam.context';
import PhotoContext from 'context/photo.context';
import UserContext from 'context/user.context';
import { unsetAuthorizationToken } from 'utils/authorization';
import { unsetPhotos } from 'actions/photo.action';
import HttpRequest from 'utils/httpRequest';
import ExamControllerContext from 'context/examController.context';
import { call } from 'utils/caller';

function LogoutPage() {
  const location = useLocation();
  const history = useHistory();
  const [, dispatchUser] = useContext(UserContext);
  const [, dispatchExam] = useContext(ExamContext);
  const [, dispatchPhoto] = useContext(PhotoContext);
  const [exam, dispatch] = useContext(ExamControllerContext);

  const logout = useCallback(async () => {
    const token = localStorage.getItem('token');

    if (exam.isReady) {
      // Store current remaining time to server.
      try {
        await call(dispatch.updateAnswer);
      } catch {
        /** */
      }
    }

    if (token && !/no-request/.test(location.search)) {
      // Logout current token.
      try {
        await new HttpRequest(api.auth.logout()).silentUnauthorized().call();
      } catch {
        /** */
      }
    }

    // Cleaning up state
    call(dispatch.reset);
    unsetAuthorizationToken();
    dispatchUser(unsetUser());
    dispatchExam(unsetExams());
    dispatchPhoto(unsetPhotos());

    return Promise.resolve();
    // Tidak membutuhkan deps apapun, karena hanya perlu dipanggil sekali saja
    // saat komponen baru dimuat.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    logout().finally(() => history.replace('/login'));
  }, [history, logout]);

  return null;
}

export default LogoutPage;
