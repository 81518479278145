/* eslint-disable react/prop-types */
import { forwardRef, memo, useEffect, useState, useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { MathJax } from 'better-react-mathjax';

import Typography from 'components/Typography';
import Input from 'components/InputMinimalis';
import useBaseURL from 'hooks/useBaseURL';
import clsx from 'libs/clsx';

const ISIAN_PATTERN = /<@isian>/gi;
const URL_PATTERN = /@url\//g;
// const MATH_ML_PATTERN = /<math(.*?<\/math>)/g;

function ExamFillMinimalis(
  { question: { id, question }, answer, onAnswerChange },
  ref // eslint-disable-line no-unused-vars
) {
  const path = useBaseURL();

  // Isi dari textarea.
  const [answers, setAnswers] = useState([]);
  // const [mathElements, setMathElements] = useState([]);

  // Jumlah dari textarea yang tersedia.
  const columnTotal = useMemo(
    () => (question.match(ISIAN_PATTERN) || []).length,
    [question]
  );

  const html = useMemo(
    () => {
      let index = 0;
      let sanitizedQuestion = question;

      // Replace [@url] dengan questionImage path.
      sanitizedQuestion = sanitizedQuestion.replace(
        /@url\//g,
        `${path.questionImage}p/`
      );

      // Replace [@url] dengan questionImage path.
      sanitizedQuestion = sanitizedQuestion.replace(
        URL_PATTERN,
        `${path.questionImage}p/`
      );

      // Mereplace mathML menjadi <span mathjax="true">.
      // sanitizedQuestion = sanitizedQuestion.replace(
      //   MATH_ML_PATTERN,
      //   // eslint-disable-next-line no-plusplus
      //   () => `<span mathjax="true" index="${index++}"></span>`
      // );

      // Mereset index.
      index = 0;

      // Mengubah string <@drag> menjadi span dan diberi identitas
      // exam-popup-container="true" dan index. Tujuan pemberian identitas
      // adalah untuk mengetahui index dari setiap kolom jawaban.
      // Tujuan menggunakan span di sini adalah untuk pengelolaan lebih lanjut.
      return sanitizedQuestion.replace(
        ISIAN_PATTERN,
        // eslint-disable-next-line no-plusplus
        () => `<span exam-isian="true" index="${index++}"></span>`
      );
    },
    // Hanya render ketika ada perubahan id dan pertanyaan.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, path.questionImage, question]
  );

  // Menambahkan string mathML ke state saat komponen dimuat.
  // useEffect(() => {
  //   // Menambahkan string mathML ke dalam state.
  //   setMathElements(question.match(MATH_ML_PATTERN) || []);
  // }, [question]);

  const handleChange = (inputIndex, value) => {
    setAnswers((currentAnswers) =>
      currentAnswers.map((currentAnswer, index) =>
        index === inputIndex ? value : currentAnswer
      )
    );
  };

  // Mengubah jawaban saat ada event onblur.
  const handleBlur = () => {
    const newAnswer = answers
      .map((_answer) => _answer || '')
      .filter((v, i) => i < columnTotal)
      .join('|');

    const areAllInputEmpty = /^\|+$/.test(newAnswer);
    onAnswerChange(areAllInputEmpty ? ' ' : newAnswer);
  };

  // Mereset value apabila jawabannya direset.
  useEffect(() => {
    if (answer === ' ') {
      setAnswers(new Array(columnTotal).fill(''));
    }
  }, [answer, columnTotal]);

  // Menangani agar kolom jawaban otomatis terisi jika peserta sudah menjawab.
  useEffect(() => {
    if (answer === ' ') {
      setAnswers(new Array(columnTotal).fill(''));
    } else {
      const answerArray = answer.split('|');

      if (answerArray.length < columnTotal) {
        setAnswers(new Array(columnTotal).fill(''));
      } else {
        setAnswers(answerArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const questionArabic = /[\u0600-\u06FF]/.test(question);

  return (
    <Typography
      as="div"
      className={clsx(
        'font-readable select-none mt-5',
        questionArabic && 'xl:text-3xl text-2xl'
      )}
      dir="auto"
      style={{
        fontFamily: questionArabic ? 'Traditional Arabic' : 'inherit',
      }}
      gutterBottom
    >
      <MathJax>
        {/* Merender HTML */}
        {ReactHtmlParser(html, {
          // Melakukan transformasi terhadap elemen
          // <span exam-popup-container="true"> menjadi react component agar
          // dapat dikelola event-eventnya (onClick, dsb).
          // eslint-disable-next-line consistent-return
          transform: (node) => {
            if (
              node.name === 'span' &&
              node.attribs?.['exam-isian'] === 'true'
            ) {
              // Index dari kolom.
              const index = parseInt(node.attribs.index, 10);

              // Jawaban peserta berdasarkan index.
              // Jawaban yang dipilih pada kolom jawaban index ini.
              const currentAnswer = answers[index];

              return (
                <Input
                  as="input"
                  id={`fill-${index}`}
                  name={`fill-${index}`}
                  label=""
                  value={currentAnswer}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onBlur={handleBlur}
                  className="mb-5"
                />
              );
            }

            // if (node.name === 'span' && node.attribs?.mathjax === 'true') {
            //   const index = parseInt(node.attribs.index, 10);
            //   const mathElement = mathElements[index];

            //   if (typeof mathElement === 'string') {
            //     return (
            //       <MathJax
            //         key={uuidv4()}
            //         math={String.raw`${mathElement}`}
            //         style={{ display: 'inline-block' }}
            //       />
            //     );
            //   }
            // }
          },
        })}
      </MathJax>
    </Typography>
  );
}

ExamFillMinimalis = forwardRef(ExamFillMinimalis);

export default memo(ExamFillMinimalis);
