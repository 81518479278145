import { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash.debounce';

import { call } from 'utils/caller';
import Exam from 'components/Exam';
import ExamControllerContext from 'context/examController.context';
import { SIDEBAR_EXAM } from 'constant';
import GuestDashboardContainer from 'components/Layouts/GuestDashboard';
import useAlert from 'hooks/useAlert';

const SimulasiPage = () => {
  const history = useHistory();
  const [{ isSimulation, isStart, isReady, examId }, dispatch] = useContext(
    ExamControllerContext
  );

  // Menangani apabila jawaban peserta diubah.
  const handleAnswerChange = useCallback(
    (index, newAnswer) => call(dispatch.answer, index, newAnswer),
    [dispatch.answer]
  );

  // Dibungkus dalam debounce supaya hanya ter-trigger satu kali.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showSuccess = useCallback(
    debounce(() => {
      useAlert.getState().show({
        title: 'Sesi simulasi berakhir',
        severity: 'success',
      });
    }, 100),
    []
  );

  // Menyelesaikan ujian.
  const handleFinish = useCallback(() => {
    showSuccess();
    call(dispatch.reset);
    history.push('/dashboard');
  }, [dispatch.reset, history, showSuccess]);

  // Memulai ujian simulasi saat halaman baru pertama kali dibuka.
  useEffect(() => {
    if (!isStart) call(dispatch.startSimulation);
  }, [dispatch.startSimulation, isStart]);

  // Menyelesaikan ujian saat peserta menekan tombol keluar.
  useEffect(() => () => handleFinish(), [handleFinish]);

  return (
    <GuestDashboardContainer activeSidebarItem={SIDEBAR_EXAM}>
      {
        // Jika belum siap, maka tampilkan loading.
        !isStart || !isSimulation || !examId || !isReady ? (
          <span>Tunggu sebentar...</span>
        ) : (
          <Exam
            isSimulation
            onAnswerChange={handleAnswerChange}
            onFinish={handleFinish}
          />
        )
      }
    </GuestDashboardContainer>
  );
};

export default SimulasiPage;
