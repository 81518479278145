export const IMAGE_EXTENSION = 'jpeg';
export const IMAGE_MIME_TYPE = 'image/jpeg';

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;

export const SIDEBAR_EXAM = 'SIDEBAR_EXAM';
export const SIDEBAR_HELP = 'SIDEBAR_HELP';

// Status yang ada pada tabel d_ujia
export const EXAM_NOT_READY = 0;
export const EXAM_READY = 1;

// Status yang ada di tabel t_pkujps
export const EXAM_PACKET_NOT_STARTED_YET = 0;
export const EXAM_PACKET_STARTED = [1, 2, 3];
export const EXAM_PACKET_FINISHED = 4;

// Digunakan untuk menentukan status pada UI
export const EXAM_STATUS_NOT_ACTIVE = 0;
export const EXAM_STATUS_STAGE1 = 1;
export const EXAM_STATUS_STAGE2 = 2;
export const EXAM_STATUS_STAGE3 = 3;
export const EXAM_STATUS_DONE = 4;
export const EXAM_STATUS_ACTIVE = 98;
export const EXAM_STATUS_WRONG_DATE = 99;

// Window event.
export const FIREBASE_TOKEN_CHANGED = 'FIREBASE_TOKEN_CHANGED';
export const SHOW_UPLOAD_PHOTO_WITH_CARD = 'SHOW_UPLOAD_PHOTO_WITH_CARD';
export const RECONNECTED = 'RECONNECTED';
export const STAGE_CHANGED = 'STAGE_CHANGED';
export const EXAM_IS_OVER = 'EXAM_IS_OVER';
export const HAS_WARNING = 'HAS_WARNING';
export const HELP_ANSWERED = 'HELP_ANSWERED';
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';

// export const STAGE_FIELDS = {
//   ssw: { 1: 'ssw', 2: 'ssw2', 3: 'ssw3' },
//   jwb: { 1: 'jwb', 2: 'jwb2', 3: 'jwb3' },
//   wtj: { 1: 'wtj', 2: 'wtj2', 3: 'wtj3' },
//   wth: { 1: 'wth', 2: 'wth2', 3: 'wth3' },
//   paket_soal: { 1: 'paket_soal', 2: 'paket_soal_2', 3: 'paket_soal_3' },
// };

// Context actions
export const SET_CAMERA_ID = 'SET_CAMERA_ID';

export const SET_BACKEND_URL = 'SET_BACKEND_URL';
export const SET_CONFIG = 'SET_CONFIG';
export const UNSET_BACKEND_URL = 'UNSET_BACKEND_URL';

export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';

export const SET_EXAM = 'SET_EXAM';
export const SET_EXAMS = 'SET_EXAMS';
export const UNSET_EXAM = 'UNSET_EXAM';
export const UNSET_EXAMS = 'UNSET_EXAMS';

export const START_EXAM = 'START_EXAM';
export const START_EXAM_SIMULATION = 'START_EXAM_SIMULATION';
export const PAUSE_EXAM = 'PAUSE_EXAM';
export const RESUME_EXAM = 'RESUME_EXAM';
export const SET_EXAM_PROGRESS = 'SET_EXAM_PROGRESS';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const SET_EXAM_PROGRESS_ANSWER = 'SET_EXAM_PROGRESS_ANSWER';
export const UNSET_EXAM_PROGRESS = 'UNSET_EXAM_PROGRESS';
export const DECREMENT_REMAINING_TIME = 'DECREMENT_REMAINING_TIME';
export const READ_QUESTION = 'READ_QUESTION';

export const SET_SELFIE_PHOTO = 'SET_SELFIE_PHOTO';
export const UNSET_SELFIE_PHOTO = 'UNSET_SELFIE_PHOTO';
export const UNSET_PHOTOS = 'UNSET_PHOTOS';

export const SET_CAMERA_PERMISSION = 'SET_CAMERA_PERMISSION';
export const SET_MICROPHONE_PERMISSION = 'SET_MICROPHONE_PERMISSION';
export const SET_NOTIFICATION_PERMISSION = 'SET_NOTIFICATION_PERMISSION';
