import propTypes from 'prop-types';

import Sidebar from './Sidebar';

function DashboardContainer({
  activeSidebarItem,
  showProfileOnSidebar,
  children,
}) {
  return (
    <div className="grid grid-cols-12">
      <div className="2xl:pl-80 xl:pl-72 mx-6 py-7 md:col-span-12 relative min-h-screen">
        {children}
      </div>

      <Sidebar
        activeItem={activeSidebarItem}
        showProfileCard={showProfileOnSidebar}
      />
    </div>
  );
}

DashboardContainer.defaultProps = {
  showProfileOnSidebar: false,
};

DashboardContainer.propTypes = {
  activeSidebarItem: propTypes.string.isRequired,
  showProfileOnSidebar: propTypes.bool,
  children: propTypes.node.isRequired,
};

export default DashboardContainer;
