import propTypes from 'prop-types';
import { createContext, useReducer } from 'react';

import userReducer, { initialUserState } from 'reducers/user.reducer';

const UserContext = createContext();

export function UserContextProvider({ children }) {
  return (
    <UserContext.Provider value={useReducer(userReducer, initialUserState)}>
      {children}
    </UserContext.Provider>
  );
}

UserContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default UserContext;
