/* eslint-disable react/prop-types */
import Progress from 'components/Progress';
import Card from 'components/Card';
import Typography from 'components/Typography';

function ExamNavigation({
  currentQuestionIndex,
  remainingTime,
  percentage,
  questionTotal,
  answeredQuestionTotal,
  ...props
}) {
  return (
    <Card className="fixed bottom-0 pt-5" {...props}>
      <div className="flex justify-between items-center">
        {/* Previous button */}
        {/* <Button
          type="button"
          color="primary-outlined"
          onClick={onPreviousButtonClick}
          className={clsx(currentQuestionIndex === 0 && 'invisible')}
        >
          Sebelumnya
        </Button> */}

        {/* Next button */}
        {/* <Button
          type="button"
          onClick={onNextButtonClick}
          className={clsx(
            currentQuestionIndex + 1 === questionTotal && 'invisible'
          )}
        >
          Selanjutnya
        </Button> */}
      </div>

      {/* Perccentage of progress */}
      <div className="mt-8">
        <Progress percentage={percentage} />
        <Typography className="text-center mt-3">
          <span className="text-gray-600">Terjawab </span>
          {answeredQuestionTotal} <span className="text-gray-600">dari</span>{' '}
          {questionTotal}{' '}
          <span className="text-green-600 font-semibold">({percentage}%)</span>
        </Typography>
      </div>
    </Card>
  );
}

export default ExamNavigation;
