/* eslint-disable react/prop-types */
import { useCallback, useEffect, useState } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';

import LoginWithUsername from './LoginWithUsername';

import { ReactComponent as HelpImage } from 'assets/illustrations/confused.svg';
import { Modal, ModalContent, ModalHeader } from 'components/Modal';
import Help from 'components/Help';
import api from 'api';
import Button from 'components/Button';
import Alert from 'components/Alert';
import { handleHttpError } from 'utils/httpError';
import Typography from 'components/Typography';
import HttpRequest from 'utils/httpRequest';

function LoginHelp({ isOpen, onClose }) {
  const [data, setData] = useState({});
  const [isValidUsername, setIsValidUsername] = useState(false);

  const handleClose = useCallback(() => {
    setData({});
    setIsValidUsername(false);
    onClose();
  }, [onClose]);

  const handleLoginSubmit = useCallback(
    async (values, { setSubmitting, setErrors }) => {
      try {
        const result = await new HttpRequest(api.help.confirmUsername())
          .setData(values)
          .call();
        setData(result.data.data);
        setIsValidUsername(true);

        localStorage.setItem('lastLoginUsername', values.username);
      } catch (err) {
        if (err.response.status === 400) setErrors(err.response.data.errors);
        else handleHttpError(err);

        setSubmitting(false);
      }
    },
    []
  );

  useEffect(() => {
    if (data.id) {
      new HttpRequest(api.profile.updateFirebaseTokenByUserId(data.id))
        .setData({ firebaseToken: localStorage.getItem('firebaseToken') })
        .call();
    }
  }, [data.id]);

  return (
    <Modal size="md" isOpen={isOpen} onClose={handleClose}>
      <ModalHeader>Bantuan</ModalHeader>
      <ModalContent className="px-0 pt-0">
        {isValidUsername ? (
          <>
            <Help
              userId={data.id}
              userCode={data.kdr}
              userName={data.nama}
              questionStatus={data.staTanya}
              question={data.textTanya}
              questionCategory={data.katTanya}
              answer={data.textJawab}
              onSuccess={handleClose}
            />
            <div className="px-5 mt-3 text-right">
              <Button color="secondary" onClick={handleClose} block>
                Kembali
              </Button>
            </div>
          </>
        ) : (
          <>
            <Alert
              title={
                <Typography className="text-gray-500 font-medium" gutterBottom>
                  Helpdesk
                </Typography>
              }
              color="success"
            >
              <Typography className="font-semibold">
                Ruang Ujian CBT AKMI
              </Typography>
              <FaRegQuestionCircle className="absolute right-5 top-9 object-center text-4xl text-green-200" />
            </Alert>
            <Typography className="font-normal text-center text-gray-500 mt-6">
              Silahkan ajukan permasalahan Anda
            </Typography>
            <HelpImage className="w-full max-w-xs h-auto mx-auto mt-8" />
            <div className="px-5 mt-4">
              <LoginWithUsername
                onSubmit={handleLoginSubmit}
                onClose={handleClose}
              />
            </div>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}

export default LoginHelp;
